/* Local Fonts Style Declaration Starts */

@font-face {
  font-family: "Georgia-Regular";
  src: url("../fonts/georgia.ttf");
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "BrandonGrotesque-Regular";
  src: url("../fonts/BrandonGrotesque-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "BrandonGrotesque-Medium";
  src: url("../fonts/BrandonGrotesque-Medium.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "BrandonGrotesque-Bold";
  src: url("../fonts/BrandonGrotesque-Bold.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Champion-HTF-Bantamweight";
  src: url("../fonts/Champion-HTF-Bantamweight.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Sentinel-Book";
  src: url("../fonts/Sentinel-Book.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Sentinel-Medium";
  src: url("../fonts/Sentinel-Medium.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Sentinel-Light";
  src: url("../fonts/Sentinel-Light.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Sentinel-MediumItal";
  src: url("../fonts/Sentinel-MediumItal.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "NunitoSans-Regular";
  src: url("../fonts/NunitoSans-Regular.ttf");
}

/* Local Fonts Style Declaration Ends */

/* Scrollbar style starts */

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Scrollbar style ends */

html {
  scroll-behavior: smooth;
}

body {
  background-image: none;
}

/* .appnavbar {
  background: url('../images/light-blue-color-solid-crop.png') no-repeat center;
} */

.appnavbar>nav {
  background-color: transparent !important;
  padding: 0 1rem;
}

.appnavbar>nav li a {
  text-transform: uppercase;
  font-size: 13px;
  font-family: BrandonGrotesque-Medium;
  letter-spacing: 0.88px;
  color: #fff !important;
  padding: 0.4rem 1rem;
}

.appnavbar>nav li .dropdown-menu a {
  color: #000 !important;
}

.appnavbar>nav li .signupbtn {
  color: #000 !important;
}

.appnavbar>nav li {
  margin-left: 1.1rem;
  /* margin-left: 0rem; */
  position: relative;
  padding: 1.8rem 0 1.8rem 0;
  /* padding: 1.4rem 0.8rem 1.4rem 0; */
}

.appnavbar .navbar-light .navbar-toggler-icon {
  background-image: url('../images/fa-fa-navicon.png');
}

.signupbtn {
  background-color: #eaf2ff;
  color: #000 !important;
  display: block !important;
  border-radius: 28px !important;
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.secondary-navholder {
  background-color: #f26432;
  color: #fff;
  position: sticky;
  top: 0;
  z-index: 1001;
  width: 100%;
  margin-top: -1px;
}

.pDisplay {
  display: none !important;
}

.secondary-navholder ul a {
  text-transform: uppercase;
  /* font-size: 14px; */
  font-size: 12px;
  font-family: BrandonGrotesque-Medium;
  letter-spacing: 0.88px;
  color: #fff !important;
  padding: 0.9rem 1rem;
  padding-right: 1.3rem;
  display: flex;
  align-items: center;
}

.mastheader-holder {
  background-color: #262626;
  color: #fff;
  /* padding: 2rem 2rem 2rem 2.2rem; */
  padding: 3.75rem 2rem;
  position: relative;
}


.mastheader-holder .heading h1 {
  margin-top: 1rem;
  text-transform: uppercase;
}

.masterWrap:before {
  content: "";
  position: absolute;
  top: 50px;
  left: 0px;
  width: 0;
  height: 0;
  border-top: 3rem solid transparent;
  border-bottom: 3rem solid transparent;
  border-left: 5rem solid #f26432;
}

/*PartnerModule */

.modalCol {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 1rem;
  background-color: #3E3E3E;
}

.modalCol2 {
  background-color: #F3F4F7;

}

.modalContent .modal-header {
  border-bottom: none !important;

}

.modalContent .modal-header .close {
  font-size: 3rem;
  color: #f26432;
}

.modalContent .modalHeading {
  color: #f26432;
  font-family: "Champion-HTF-Bantamweight";
  font-weight: normal;
  font-style: normal;
  font-size: 35px;
  margin-bottom: 0%;
  text-transform: uppercase;
  letter-spacing: 3.15px;
  margin-top: -3%;
}

.modalContent .modalPara {
  font-family: "BrandonGrotesque-Medium";
  font-weight: normal;
  font-style: normal;
  font-size: 16px;

}

.modalContent .modalSectionSite {
  float: right;
  font-family: "BrandonGrotesque-Regular";
  text-decoration: underline;
  font-size: 16px;
  color: #3A7FDC;
  font-weight: normal;
  font-style: normal;
}

.modal-content {
  background-color: transparent !important;
}

.partnerLogoHolder {
  width: 140px;
  height: 140px;

  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.partnerLogoMainHolder {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  flex-wrap: wrap;

}

.partnerLogoText {
  text-transform: uppercase;
  font-family: "BrandonGrotesque-Medium";
  font-weight: 600;
  margin-top: 10px;
  text-align: center;
  width: 180px;
}

.partnerModule .partnerTitle,
.collectiveImpact .collectiveTitle {
  font-family: "Champion-HTF-Bantamweight";
  font-weight: normal;
  font-size: 2.5rem;
  letter-spacing: 2.8px;
  color: #E16C41;
  text-transform: uppercase;
  opacity: 1;
  font-style: normal;
  line-height: 3.375rem;
}

.partnerBg {
  background: #F3F4F7 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 3px 6px #00000029;
  opacity: 1;
}

.partners-projects-container .secondary-navholder { display: none; }

.secondary-navholder .justify-content-center.nav { justify-content: space-between !important; }

.reportPage .partnerBtn {
  margin-top: 1.4rem;
  background-color: #0B7451 !important;
  color: #fff;
  letter-spacing: 0px;
  font-size: 0.8rem;
  line-height: 1.25rem;
  border: none;
  border-radius: 10px !important;
  font-family: "BrandonGrotesque-Medium";
  width: 98%;
  max-width: 100%;
  height: 49px;
  opacity: 1;
  box-shadow: 0px 3px 6px #00000029;
  display: flex;
  justify-content: center;
  align-items: center;
}

.reportPage .partnerBtn:hover {
  background-color: #0B7451 !important;
  color: #fff;
  text-decoration: none;
}

.reportPage .reportImg {

  /* top: 685px;
    left: 71px; */
  width: 96% !important;
  height: auto;
  max-width: 100%;
}

.reportPage .downloadReport {

  width: 6%;
  position: relative;
  /* content: ' '; */
  left: -5px
}

.reportPage .reportContent {
  margin-top: 12%;
}

.reportContent p {
  text-align: left;
  letter-spacing: 0;
  /* color: #fff; */
  font-family: "BrandonGrotesque-Regular";
  font-style: normal;
  font-weight: normal;
  color: #000000;
  opacity: 1;
  font-size: 16px;
  line-height: 1.4375rem;
  /*23px*/
  /* padding: 21px; */
}



.reportText {
  max-height: 358px;
  overflow-y: auto;
  padding-right: 5px;
  padding: 16px;
}

.reportDiv-header {

  text-align: left;
  opacity: 1;
  padding-bottom: 5px;
  color: #E16D41;
  text-transform: uppercase;
  font-family: "BrandonGrotesque-Medium";
  line-height: 19px;
  font-size: 13px;
  letter-spacing: 0.87px;
}

.partnerHeading {

  font-family: 'Champion-HTF-Bantamweight';
  font-weight: normal;
  font-size: 2.5rem;
  letter-spacing: 3.5px;
  margin-top: 2rem;
  color: #3E3E3E;
  text-transform: uppercase;
  opacity: 1;
  font-style: normal;
  line-height: 3.375rem;

}

.bg-greyish {
  background: #F3F4F7 0% 0% no-repeat padding-box !important;

}

.reportLogoImg {
  width: 141px;
  height: auto;
  margin-top: 26%;
  max-width: 100%;
}

.partnerDivContainer {

  width: 330px;
  height: 90px;
  border-radius: 14px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 19px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;

}


.box-orange .partnerDivContainer {
  background: #F9E5E0 0% 0% no-repeat padding-box;
}

.box-green .partnerDivContainer {
  background: #E6F1DA 0% 0% no-repeat padding-box;
}

.box-blue .partnerDivContainer {
  background: #DDEFF2 0% 0% no-repeat padding-box;
}

.partnerPara {
  text-align: center;
  font-family: "BrandonGrotesque-Regular";
  font-style: normal;
  font-size: 20px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  line-height: 24px;
  padding: 10px;
}

.portallogodiv4 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 15px;
  margin-top: 0%;
  margin-bottom: 6%;
}

.portallogomaindiv {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: var(---ffffff) 0% 0% no-repeat padding-box;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: inset 1px 4px 5px #00000033;
  opacity: 1;
  border-radius: 10px;
}

.partnerLogoMainHolder4 {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  flex-wrap: wrap;
}

.partnerLogos {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}

.partnerLogos img {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}

.partnerLogoHolder4 {
  width: 140px;
  height: auto;

  /* border-radius: 10px; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2%;
}

.partnerLogoHeader {
  letter-spacing: 0px;
  color: #FFFFFF;
  text-transform: uppercase;
  opacity: 1;
  font-family: "BrandonGrotesque-Regular" !important;
  text-align: center !important;
  font-size: 9px;
  /* text-align: left; */
  font-weight: normal;
  font-style: normal;
  line-height: 27px;
  /* margin-left: 36%; */
}


.reportText::-webkit-scrollbar {
  width: 13px;
}

.reportText::-webkit-scrollbar-track {
  background: var(--unnamed-color-aaaaaa) 0% 0% no-repeat padding-box;
  background: #AAAAAA 0% 0% no-repeat padding-box;
  border-radius: 9px;
  opacity: 1;
}

.partnerDivCard {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 3px 6px #0000004d;
  opacity: 1;
  border-radius: 14px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 137px;
  flex-direction: column;
  padding: 0 30px;
}

.sectionPara {

  text-align: center;
  font-size: 21px;
  letter-spacing: 0px;
  color: #7F7F7F !important;
  opacity: 1;
  font-family: Sentinel;
  font-style: italic;
  font-weight: normal;
  line-height: 25px;

}

/*m&eCardsactivecss*/


.border-Orange9 {
  border: 2px solid #F65D34;
  border-radius: 10px;
}

.box-OrangeFramework,
.box-OrangeFramework3,
.box-OrangeFramework6 {
  width: 330px;
  height: 90px;
  border-radius: 14px;
  box-shadow: inset 0px 3px 6px #00000029;
  border-radius: 19px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F65D34;
  color: #fff;

}


.box-OrangeFramework .partnerPara,
.box-OrangeFramework3 .partnerPara,
.box-OrangeFramework6 .partnerPara,
.box-GreenFramework1 .partnerPara,
.box-GreenFramework4 .partnerPara,
.box-GreenFramework7 .partnerPara,
.box-BlueFramework2 .partnerPara,
.box-BlueFramework5 .partnerPara,
.box-BlueFramework8 .partnerPara {
  color: #fff;
}

.box-GreenFramework1,
.box-GreenFramework4,
.box-GreenFramework7 {
  background-color: #81B449;
  width: 330px;
  height: 90px;
  border-radius: 14px;
  box-shadow: inset 0px 3px 6px #00000029;
  border-radius: 19px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.box-BlueFramework2,
.box-BlueFramework5,
.box-BlueFramework8 {
  background-color: #1DA0BC;
  width: 330px;
  height: 90px;
  border-radius: 14px;
  box-shadow: inset 0px 3px 6px #00000029;
  border-radius: 19px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bigBoxOrange-framework .sectionPara,
.bigBoxOrange-framework3 .sectionPara,
.bigBoxOrange-framework6 .sectionPara,
.bigBoxGreen-framework1 .sectionPara,
.bigBoxGreen-framework4 .sectionPara,
.bigBoxGreen-framework7 .sectionPara,
.bigBoxBlue-framework2 .sectionPara,
.bigBoxBlue-framework5 .sectionPara,
.bigBoxBlue-framework8 .sectionPara {
  font-style: normal;
  color: #fff !important;

}

.bigBoxOrange-framework,
.bigBoxOrange-framework3,
.bigBoxOrange-framework6,
.borderOrange-framework {
  background-color: #F65D34 !important;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 3px 6px #0000004d;
  opacity: 1;
  border-radius: 14px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 137px;
}

.bigBoxGreen-framework1,
.bigBoxGreen-framework4,
.bigBoxGreen-framework7 {
  background-color: #81B449 !important;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 3px 6px #0000004d;
  opacity: 1;
  border-radius: 14px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 137px;


}

.bigBoxBlue-framework2,
.bigBoxBlue-framework5,
.bigBoxBlue-framework8 {
  background-color: #1DA0BC !important;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 3px 6px #0000004d;
  opacity: 1;
  border-radius: 14px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 137px;
}


/*CollectiveImpact */
.collectiveImpact .heading h1 {
  letter-spacing: 3.15px !important;
  color: #3E3E3E !important;

  opacity: 1;
}

.bg-lgGrey {
  background: #F3F4F7 0% 0% no-repeat padding-box;
  opacity: 1;
}

.collectivePara p {
  text-align: left;
  /* font-size: 18px !important; */
  font-family: "BrandonGrotesque-Regular" !important;
  font-weight: normal;
  letter-spacing: 0px;
  color: #3E3E3E;
  opacity: 1;
  line-height: 26px;
}

.collectiveHeading {
  letter-spacing: 1px;
  color: #504D4C;
  text-transform: uppercase;
  opacity: 1;
  font-family: "BrandonGrotesque-Regular" !important;
  line-height: 22px;
  font-size: 15px;
}

.partnerActive .nav-link.active {
  color: #FFFFFF !important;
  background-color: #52B335 !important;
  border-color: #dee2e6 #dee2e6 #fff;
  box-shadow: inset 0px 3px 6px #011E05A6;
}

.collectiveActive .nav-link.active {
  color: #FFFFFF !important;
  background-color: #015C84 !important;
  border-color: #dee2e6 #dee2e6 #fff;
  box-shadow: inset 0px 3px 6px #011E05A6;
}


.tabPartner li {
  width: 50%;
  text-align: center;
}

.partnerActive a {
  color: #52B335 !important;
  font-size: 0.9rem;
  /*14px*/
  font-family: BrandonGrotesque-Regular;
  font-weight: bold;
  /* text-align: left; */
  font-style: normal;
  letter-spacing: 0px;
  text-transform: uppercase;
  opacity: 1;
  cursor: pointer;
}

.collectiveActive a {
  color: #015C84;
  font-size: 0.9rem;
  font-family: BrandonGrotesque-Regular;
  font-weight: bold;
  /* text-align: left; */
  font-style: normal;
  letter-spacing: 0px;
  text-transform: uppercase;
  opacity: 1;
  cursor: pointer;
}





@media screen and (max-width: 1375px) {
  .masterWrap:before {
    left: -8px;
  }
}

@media screen and (min-width: 1375px) {
  .masterWrap .heading:before {
    left: -420px;
  }
}

.mastheader-holder .heading h1 {
  font-family: Champion-HTF-Bantamweight;
  font-size: 4.5rem;
  line-height: 6rem;
  font-weight: 400;
  letter-spacing: 8.4px;
  color: #fff;
  text-transform: uppercase;
}

.partial-underline {
  margin-bottom: 1.4rem;
  position: relative;
  display: inline-block;
}

.mastheader-holder .partial-underline:after {
  bottom: -5px;
}

.partial-underline:after {
  content: "";
  position: absolute;
  bottom: -20px;
  width: 70px;
  left: 0;
  border-bottom: 2px solid #f26432;
}

.mast-description p {
  font-size: 1.31rem;
  line-height: 2rem;
  margin: 1rem 0;
  font-family: Sentinel-Medium;
}

.mastheader-holder .container .row div .mast-description p a{
  color: #f26432;
}

.mastheader-holder .mast-description p a {
  color: #fff;
}

.dashboardtemplate-holder.reportPage a:hover { text-decoration: none; }

.text-orange {
  color: #f26432;
}

.mast-imagecontainer {
  position: relative;
  top: 2rem;
  margin-bottom: 3rem;
}

.mast-imagecontainer img {
  position: relative;
  z-index: 2;
  display: block;
  width: 100%;
}

.mast-imagecontainer>div {
  position: relative;
  max-width: 600px;
  left: 1rem;
}

.mast-imagecontainer>div:before {
  content: "";
  position: absolute;
  top: 0.6rem;
  left: 0.6rem;
  width: 100%;
  height: 100%;
  background-color: #f26432;
  z-index: 1;
}

.profile-imagecontainer {
  position: relative;
  top: 2rem;
  margin-bottom: 3rem;
}

.profile-imagecontainer img {
  position: relative;
  z-index: 2;
  display: block;
  width: 100%;
}

.profile-imagecontainer>div {
  position: relative;
  max-width: 600px;
  left: 1rem;
}


.dashboardtemplate-holder {
  background-color: #fff;
  color: #514d4c;
  padding: 2rem 2rem 2rem 2.2rem;
  position: relative;
}

.dashboardtemplate-holder .heading h2 span {
  color: #313131;
  margin-top: 28px;
  font-size: 1.625rem;
  letter-spacing: 0.25px;
  text-transform: uppercase;
  font-family: BrandonGrotesque-Medium;
  font-weight: 400;
  margin-bottom: 1rem;
  border-bottom: 2px solid #f26432;
}

.db-description {
  font-size: 1.2rem;
  line-height: 2rem;
  margin: 1.5rem 0;
  font-family: Sentinel-Medium;
}

.dbtemplate-card {
  padding: 1rem 1rem;
  padding-bottom: 2rem;
  border-radius: 6px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
}

.dbtemplate-card img {
  border-radius: 6px;
}

.dbtemplateinfo-container h3 {
  text-transform: uppercase;
  font-family: BrandonGrotesque-Medium;
  font-weight: 600;
  color: #262626;
  font-size: 1.15rem;
  height: 66px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dbtemplateinfo-container {
  padding: 1rem 0rem;
}

.viewmore-link {
  position: absolute;
  bottom: 1.2rem;
  left: 2rem;
  display: block;
  font-size: 0.8125rem;
  letter-spacing: 1px;
  line-height: 1.1875rem;
  text-transform: uppercase;
  font-family: BrandonGrotesque-Medium;
  transition: all 0.3s;
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;
}

.viewmore-link:hover {
  color: #124168;
  text-decoration: none;
}

.viewmore-link:hover:before {
  right: -56px;
}

.viewmore-link:before {
  transition: right 0.3s;
  content: "\279E";
  /* font-family: 'Font Awesome 5 Free';*/
  position: absolute;
  right: -34px;
  top: 42%;
  transform: translateY(-50%);
  font-size: 20px;
}

.explorealltempbtn {
  padding: 1rem 2rem;
  margin-top: 1.4rem;
  background-color: #0f6dbe;
  color: #fff;
  letter-spacing: 1.99px;
  font-size: 0.875rem;
  line-height: 1.25rem;
  border: none;
  border-radius: 28px !important;
  font-family: BrandonGrotesque-Medium;
}

.explorealltempbtn:hover {
  background-color: #045296;
  color: #fff;
  text-decoration: none;
}

.bg-grey {
  background-color: #f3f4f8;
}

.builddbtext {
  color: #4f5052;
  font-size: 1.525rem;
  letter-spacing: 0.25px;
  font-family: Sentinel-Medium;
  font-weight: 600;
}

.builddbformcontainer {
  color: #4f5052;
  font-size: 1.525rem;
  letter-spacing: 0.25px;
  font-family: Sentinel-Medium;
  font-weight: 600;
}

.builddbformcontainer select {
  width: fit-content;
  display: inline-block;
  color: #4f5052;
  font-size: 1.525rem;
  letter-spacing: 0.25px;
  font-family: Sentinel-Medium;
  font-weight: 600;
  background-color: transparent;
  border-bottom: 2px solid #c4c3c8 !important;
  border-radius: 0px;
  padding-left: 0px;
  padding-right: 0px;
}

.infotext {
  color: #797171;
  font-size: 12px;
  font-weight: 400;
  font-family: Sentinel-Medium;
  margin-top: 20px;
}

.builddbformcontainer .form-group {
  display: inline-block;
  margin-right: 20px;
}

.ml-20px {
  margin-left: 20px;
}

.mr-20px {
  margin-right: 20px;
}

.bulbimg {
  width: 20px;
  margin-right: 5px;
  margin-top: -8px;
}

.dbview-card {
  padding: 1rem 1rem;
  border-radius: 6px;
  height: 100%;
  background-color: #fff;
}

.dbview-card:hover,
.dbview-card.active {
  padding: 1rem 1rem;
  border-radius: 6px;
  height: 100%;
  background-color: #f3f4f8;
  cursor: pointer;
}

.viewheadingtitle {
  text-transform: uppercase;
  font-family: BrandonGrotesque-Medium;
  font-weight: 600;
  color: #262626;
  font-size: 1rem;
  border-bottom: 2px solid #262626;
}

.view-description {
  color: #262626;
  font-size: 14px;
  font-weight: 400;
  font-family: Sentinel-Medium;
  margin-bottom: 10px;
}

.fw-600 {
  font-weight: 600;
}

button.explorealltempbtn {
  font-size: 14px;
  padding: 16px 32px;
  background: #0f6dbe;
}

button.explorealltempbtn:hover {
  background-color: #045296;
  color: #fff;
  text-decoration: none;
}

#topic-filter>div,
#country-filter>div,
#region-filter>div,
#strategy-filter>div,
#beneficiary-filter>div,
#data-source-filter>div {
  border-bottom: 2px solid #c4c3c8 !important;
  border: none;
  border-radius: 0px !important;
}

.builddbformcontainer div span {
  padding: 0px !important;
  background: transparent;
  margin-right: 5px;
  margin-bottom: 5px;
  border-radius: 10px;
  display: inline-flex;
  align-items: center;
  font-size: 13px;
  color: #f26432;
  white-space: nowrap;
  font-size: 1.525rem;
  letter-spacing: 0.25px;
  font-family: Sentinel-Medium;
  font-weight: 600;
}

.builddbformcontainer div span i {
  font-size: 16px;
}

.builddbformcontainer ul.optionContainer li.highlight,
.builddbformcontainer ul.optionContainer li:hover {
  background: #f26432;
  color: #ffffff;
}

.hide {
  display: none !important;
}

.customtabsholder .nav-tabs {
  border-bottom: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.customtabsholder .nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
  border: none;
  color: #313131;
  font-size: 1.425rem;
  letter-spacing: 0.25px;
  text-transform: uppercase;
  font-family: BrandonGrotesque-Medium;
  font-weight: 400;
  margin-bottom: 0rem;

  opacity: 1;
}

.customtabsholder .nav-tabs .nav-link.active span,
.customtabsholder .nav-tabs .nav-link span {
  border-bottom: 3px solid #f26432;
}

.customtabsholder .nav-tabs .nav-link.active:after {
  content: "";
  width: 0;
  height: 0;
  border-top: 15px solid #f26432;
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
  display: block;
  margin: auto;
}

.customtabsholder .nav-tabs .nav-link:after {
  content: "";
  width: 0;
  height: 0;
  border-top: 15px solid #fff;
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
  display: block;
  margin: auto;
}

.customtabsholder .nav-tabs .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
  border: none;
  color: #313131;
  font-size: 1.425rem;
  letter-spacing: 0.25px;
  text-transform: uppercase;
  font-family: BrandonGrotesque-Medium;
  font-weight: 400;
  margin-bottom: 0rem;

  margin-right: 30px;
  opacity: 0.5;
  cursor: pointer;
}

.customtabsholder .dashboardtemplate-holder {
  padding-left: 0rem;
  padding-right: 0rem;
  padding-top: 0rem;
}

.customtabsholder .tab-content {
  margin-top: 20px;
}

#topic-filter,
#country-filter,
#region-filter,
#strategy-filter,
#beneficiary-filter,
#data-source-filter {
  width: fit-content;
  display: inline-block;
}

.dashboardtemplate-holder .optionContainer li {
  font-size: 14px;
}

#navbar {
  /* position: fixed;
  top: 0; */
  width: 100%;
  display: block;
  transition: top 0.3s;
  background-color: rgba(0,0,0,.9);
  z-index: 9;
  border-bottom: 1px solid #eaeaea;
}

.form-check-label {
  font-family: Sentinel-Medium;
}

.dashboardRadioBtn input[type="radio"] {
  -webkit-appearance: none;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 50%;
  outline: none;
  /* box-shadow:0 0 5px 0px #f1f1f1 inset; */
  position: relative !important;
  top: 3px;
  background-color: #f1f1f1;
  cursor: pointer;
}

.dashboardRadioBtn input[type="radio"]:hover {
  /* box-shadow:0 0 5px 0px orange inset; */
}

.dashboardRadioBtn input[type="radio"]:before {
  content: "";
  display: block;
  width: 65%;
  height: 65%;
  margin: 20% auto;
  border-radius: 50%;
}

.dashboardRadioBtn input[type="radio"]:checked:before {
  background: #f26432;
}

.btnexploresmall {
  padding: 11px 32px !important;
}

.sectiontitle {
  color: #262626;
  font-size: 1.425rem;
  letter-spacing: 0.25px;
  text-transform: uppercase;
  font-family: BrandonGrotesque-Medium;
  /* font-weight: 600; */
  margin-bottom: 6%;
}

.bluelink {
  color: #2e70c2;
  text-decoration: underline;
}

.covidportallogoholder {
  width: 140px;
  height: 140px;
  background-color: #f1edeb;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.covidportallogomainholder {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
}

.covidportallogotext {
  text-transform: uppercase;
  font-family: BrandonGrotesque-Medium;
  font-weight: 600;
  margin-top: 10px;
  text-align: center;
  width: 180px;
}

.portallogodiv {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

}

.portallogodiv>a {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center !important;
  color: #514d4c;
}

.portallogoimg {
  max-width: 80%;
  height: auto;
}

.insideportalLogo {
  max-width: 100%;
  height: auto;
}

.bg-darkgrey {
  background-color: #f1edeb;
}

.color-darkblack {
  color: #262626 !important;
}

.sectionsubtitle {
  color: #aaa;
  font-size: 1rem;
  letter-spacing: 0.25px;
  text-transform: uppercase;
  font-family: BrandonGrotesque-Medium;
  font-weight: 400;
}

.sectionsubtitle.partial-underline:after {
  content: "";
  position: absolute;
  bottom: -5px;
  width: 70px;
  left: 0;
  border-bottom: 2px solid #f26432;
}

.supplychainsubtitle.sectionsubtitle.partial-underline:after {
  border-bottom: 2px solid #78b450;
}

.digitalhealthsubtitle.sectionsubtitle.partial-underline:after {
  border-bottom: 2px solid #fba347;
}

.primaryhealthsubtitle.sectionsubtitle.partial-underline:after {
  border-bottom: 2px solid #00a1c3;
}

.resourcelogodivcontainer {
  margin-top: 34px;
  height: 75%;
  width: 80%;
  background-color: #f1ebed;
  display: flex;
  justify-content: center;
}

.sourcelink {
  font-family: BrandonGrotesque-Medium;
  font-weight: 300;
  color: #25a0c3;
  margin-top: 10px;
  font-size: 0.8125rem;
}

.countryprofiledivcontainer {
  width: 80%;
  box-shadow: 0px 0px 6px #00000040;
  border-radius: 14px;
  padding: 15px;
}

.countryname {
  font-family: BrandonGrotesque-Medium;
  font-weight: 500;
  color: #262626;
  font-size: 1.375rem;
  text-transform: uppercase;
}

#explorSection {
  /* background-color: #f2f2f2; */
  padding: 0rem 2rem 0rem 2rem;
}

.portallogodiv1 {
  background-image: url("../images/supplychain1.png");
  padding-bottom: 56%;
  height: 100%;
  /* background-position: unset; */
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 10%;
}

.portallogodiv2 {
  background-image: url("../images/digitalhealth1.png");
  padding-bottom: 61%;
  height: 100%;
  background-position: left;
  background-size: contain;
  background-repeat: no-repeat;
  margin-top: 8%;
}

.portallogodiv3 {
  background-image: url("../images/primaryhealth1.png");
  padding-bottom: 56%;
  height: 100%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-top: 9%;
}

.downloadPDF_btn {
  font-size: 1.2rem !important;
  background-color: #f26432 !important;
  letter-spacing: 0;
  width: auto;
  height: auto;
  border-radius: 4px !important;
  padding: 16px 48px 15px !important;
}

.downloadPDF_btn:hover {
  background-color: #075497 !important;
}

#topic-filter>div:first-child,
#topic-filter_input {
  max-width: 100%;
  max-height: 200px;
  overflow: hidden;
  display: flex;
  margin-right: 8px;
  justify-content: flex-start;
  padding-bottom: 0;
  /* padding-left: 10px; */
  padding-right: 10px;
}

#region-filter>div:first-child,
#region-filter_input {
  max-width: 100%;
  max-height: 200px;
  overflow: hidden;
  display: flex;
  margin-right: 8px;
  justify-content: flex-start;
  padding-bottom: 0;
  /* padding-left: 10px; */
  padding-right: 10px;
}

#country-filter>div:first-child,
#country-filter_input {
  max-width: 100%;
  max-height: 200px;
  overflow: hidden;
  display: flex;
  margin-right: 8px;
  justify-content: flex-start;
  padding-bottom: 0;
  /* padding-left: 10px; */
  padding-right: 10px;
}

#strategy-filter>div:first-child,
#strategy-filter_input {
  max-width: 100%;
  max-height: 200px;
  overflow: hidden;
  display: flex;
  margin-right: 8px;
  justify-content: flex-start;
  padding-bottom: 0;
  /* padding-left: 10px; */
  padding-right: 10px;
}

#beneficiary-filter>div:first-child,
#beneficiary-filter_input {
  max-width: 100%;
  max-height: 200px;
  overflow: hidden;
  display: flex;
  margin-right: 8px;
  justify-content: flex-start;
  padding-bottom: 0;
  /* padding-left: 10px; */
  padding-right: 10px;
}

#data-source-filter>div:first-child,
#data-source-filter_input {
  max-width: 100%;
  max-height: 200px;
  overflow: hidden;
  display: flex;
  margin-right: 8px;
  justify-content: flex-start;
  padding-bottom: 0;
  /* padding-left: 10px; */
  padding-right: 10px;
}

#topic-filter,
#region-filter,
#country-filter,
#strategy-filter,
#beneficiary-filter,
#data-source-filter {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
  position: relative;
  /* width: 100%; */
  padding-right: 15px;
  padding-left: 10px;
}

.pp_page_nav img {
  width: 170px;
}

.pp_nav_text h3 {
  color: #262626;
  font-size: 3.8rem;
  font-weight: bolder;
}

.pp_nav_text p {
  font-family: "Sentinel-Medium";
  color: #313131;
  font-style: italic !important;
  font-weight: 100 !important;
  font-size: 1.2rem;
  margin-bottom: 0;
}

.pp_page_nav .firstImg {
  width: 65px;
  margin: 0 auto;
  display: block;
}

.programProfilesPage {
  color: #313131;
}

.programProfilesPage .border-top-black {
  width: 100%;
  display: block;
  border-top: 3px solid #262626;
}

.programProfilesPage .blacksection {
  background-image: url("../images/polygon21.svg");
  background-color: #313131;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 10px 12px;
}

.programProfilesPage .blacksection.rightBannerSection {
  height: 400px;
}

.programProfilesPage .greySideBlackSection {
  background-size: 120px;
  position: relative;
  height: 400px;
  /* height: 450px; */
}

.programProfilesPage .greySideBlackSection .pp_logoSection {
  /* height: 100px;
  overflow: hidden;
  overflow-y: auto; */
  margin-bottom: 43px;
  margin-top: 25px;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
}

.programProfilesPage .greySection {
  background-color: #dfdfdf;
}

.updateDateDiv.text-white {
  font-family: "Sentinel-Light";
  text-transform: uppercase;
  font-size: 0.8rem;
}

.titleName.text-white {
  font-size: 2rem;
  text-transform: uppercase;
}

.pp_logoSection .brandLogo {
  margin: 0 auto;
  display: block;
  width: 100%;
}

/* .pp_logoSection .multipleImage .brandLogo{
  width: 55%;
} */
.pp_logoSection .multipleImage .imageDiv_brandLogo {
  height: 100%;
  width: 135px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.pp_logoSection p.text-warning {
  font-size: 1.3rem;
  font-family: "Sentinel-Light";
  font-style: italic;
  /* position: relative; */
  /* top: -25px; */
  color: #faa73f !important;
  position: absolute;
  bottom: 0;
  z-index: 9;
  width: 100%;
  left: 0;
}

.pp_logoSection::-webkit-scrollbar {
  width: 0.3em;
}

.pp_logoSection::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.pp_logoSection::-webkit-scrollbar-thumb {
  background-color: #494949;
  outline: 1px solid #494949;
}

.greySection .regionTextData {
  padding: 12px 12px;
}

.greySection .regionTextData p span {
  font-weight: bold;
}

.greySection .regionTextData p {
  font-size: 16px;
  color: #313131;
  font-weight: 500;
}

.greySection .mapSection {
  height: 250px;
  padding: 12px 12px;
}

.objectiveDataDiv {
  height: 200px;
  overflow: hidden;
  padding: 12px 12px;
}

.objectiveDataDiv h5 {
  font-style: italic;
  border-bottom: 2px solid #f3b35f;
  padding-bottom: 5px;
  width: 50%;
  font-size: 22px;
  color: #262626;
}

.objectiveDataDiv p {
  font-family: "Sentinel-Light";
  font-size: 14px;
  color: #262626;
  line-height: 1.1;
}

.milestonesDiv {
  height: 500px;
  padding: 12px 12px;
}

.milestonesDiv h5 {
  font-style: italic;
  border-bottom: 2px solid #f3b35f;
  padding-bottom: 5px;
  width: 50%;
  font-size: 22px;
  color: #262626;
}

.milestonesDiv ul li {
  font-family: "Sentinel-Light";
  font-size: 14px;
  color: #262626;
  line-height: 1.1;
  margin-bottom: 2%;
}

.milestonesDiv ul {
  list-style-type: none;
}

.milestonesDiv ul li {
  display: flex;
}

.milestonesDiv ul li p {
  margin-bottom: 0;
}

.milestonesDiv ul li:before {
  content: "";
  display: inline-block;
  height: 15px;
  width: 15px;
  background-size: 8px;
  background-image: url(/static/media/polygon21.bd991e22.svg);
  background-repeat: no-repeat;
  margin-right: 6px;
}

.discriptionSection .desp-wrap {
  padding: 12px 12px;
}

.discriptionSection .desp-wrap .collabration-wrap h5 {
  font-style: italic;
  border-bottom: 2px solid #f3b35f;
  padding-bottom: 5px;
  width: 30%;
  font-size: 22px;
  color: #262626;
}

.collabration-wrap .colb-desp-summary p {
  font-family: "Sentinel-Light";
  font-size: 14px;
  color: #262626;
  line-height: 20px;
}

.collabration-wrap .colb-desp-summary ul,
.collabration-wrap .colb-desp-summary ol {
  margin-left: 30px;
}

.collabration-wrap .colb-desp-summary ul li,
.collabration-wrap .colb-desp-summary ol li {
  font-family: "Sentinel-Light";
  font-size: 14px;
  color: #262626;
  line-height: 20px;
}

.collabration-wrap .colb-desp-summary {
  /* min-height: 331px; */

  overflow: auto;
  padding-bottom: 5%;
  /* overflow-y: scroll; */
  border-bottom: 3px solid #262626;
}

.colb-desp-summary-height {
  max-height: 130px;
}

.bottom-logos-section .ao_img,
.bottom-logos-section .aa_img {
  width: 18%;
}

.bottom-logos-section .ao_text p {
  font-size: 14px;
  margin-left: 10px;
  font-family: "BrandonGrotesque-Regular";
  line-height: 1.4;
  color: #3d3d3d;
  margin-bottom: 0;
}

.bottom-logos-section .ao_text a {
  font-size: 14px;
  margin-left: 10px;
  font-family: "BrandonGrotesque-Regular";
  color: #25a0c3;
  text-decoration: underline;
}

.bottom-logos-section .alert-dark {
  color: #1b1e21;
  background-color: #d9d6cf;
  border-color: transparent;
  border-radius: 0;
}

.bottom-logos-section .aa_text p {
  font-size: 14px;
  margin-left: 10px;
  font-family: "BrandonGrotesque-Regular";
  line-height: 1.4;
  color: #3d3d3d;
  margin-bottom: 0;
}

.bottom-logos-section .aa_text a {
  font-size: 14px;
  margin-left: 10px;
  font-family: "BrandonGrotesque-Regular";
  color: #25a0c3;
  text-decoration: underline;
}

.discriptionSection .desp-wrap .contentDiv .ncd_focus_div h5 {
  border-bottom: 2px solid #f3b35f;
  padding-bottom: 5px;
  font-size: 18px;
  color: #262626;
  text-transform: uppercase;
  font-weight: 700;
}

.discriptionSection .desp-wrap .contentDiv .ncd_focus_div .ncd_focus_content {
  height: 200px;
  color: #212529;
  font-family: "BrandonGrotesque-Regular";
}

.discriptionSection .desp-wrap .contentDiv .sdgs_div h5 {
  border-bottom: 2px solid #f3b35f;
  padding-bottom: 5px;
  font-size: 18px;
  color: #262626;
  text-transform: uppercase;
  font-weight: 700;
}

.discriptionSection .desp-wrap .contentDiv .sdgs_div h5 span {
  text-transform: lowercase;
}

.discriptionSection .desp-wrap .contentDiv .sdgs_div .sdgs_content {
  padding-bottom: 25px;
  box-sizing: border-box;
  color: #313131;
}

.discriptionSection .desp-wrap .contentDiv .key_demo_div h5 {
  border-bottom: 2px solid #f3b35f;
  padding-bottom: 5px;
  font-size: 18px;
  color: #262626;
  text-transform: uppercase;
  font-weight: 700;
}

.discriptionSection .desp-wrap .contentDiv .key_demo_div .key_demo_content {
  height: 200px;
}

.contentDiv-header {
  border-bottom: 2px solid #f3b35f;
  padding-bottom: 5px;
  font-size: 18px;
  color: #262626;
  text-transform: uppercase;
  font-weight: 700;
}

.discriptionSection .desp-wrap .contentDiv .people_count .people_count_content {
  height: 300px;
}

.discriptionSection .desp-wrap .contentDiv .catchment_area_div .catchment_area_content {
  height: 200px;
}

.program_strat_div p {
  font-family: "BrandonGrotesque-Medium";
  margin-bottom: 0;
  font-size: 14px;
  color: #504d4c !important;
}

.program_strat_div .prog_btn {
  color: #ef6230 !important;
  background-color: #d9d6cf !important;
  border-color: #d9d6cf !important;
  font-size: 12px;
  border-radius: 20px;
  font-family: "BrandonGrotesque-Regular";
}

.viewClasstext {
  color: #ef6230;
  cursor: pointer;
  font-family: "BrandonGrotesque-Regular";
  font-size: 14px;
  text-decoration: underline;
}

.beneficiary_area_div .beneficiary_area_content p {
  font-family: "Sentinel-Light";
  font-size: 14px;
  color: #514d4c;
}

.beneficiary_area_div .beneficiary_area_content p span {
  color: #ef6230;
}

.beneficiary_area_div .beneficiary_area_content .dataCount {
  padding: 0;
  border-radius: 0;
  font-size: 2rem;
  font-family: "BrandonGrotesque-Medium";
  color: #ef6230;
  background-color: #f5f5f5 !important;
  border-color: #f5f5f5 !important;
}

.partners_wrap .partners_content {
  height: 225px;
  overflow-x: hidden;
}

.programProfilesPage .footerDivWrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.programProfilesPage .footerDivWrap h5 {
  font-family: "Sentinel-MediumItal";
  color: #707070;
  margin-bottom: 0;
  margin-right: 2%;
}

.programProfilesPage .footerDivWrap img {
  width: 40% !important;
}

.programProfilesPage footer {
  height: 55px;
  background: #fff;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.ncd_focus_content textarea,
.ncd_focus_content textarea:focus {
  border: 0;
  background: #f5f5f5;
}

.people_count_content table {
  margin-bottom: 0;
}

.people_count_content table tr th {
  font-family: "BrandonGrotesque-Regular";
  font-size: 0.9rem;
}

.people_count_content table tr td {
  font-family: "BrandonGrotesque-Regular";
  font-size: 0.9rem;
  text-align: center;
}

.people_count_content .no_value_msg {
  font-family: "Georgia-Regular";
  background: #f6f6f6;
  margin-top: 2%;
  margin-bottom: 1%;
  text-align: center;
  color: #262626;
}

.key_tags_wrap {
  background: #f5f5f5;
  padding: 12px;
}

.key_demo_content .purple_btn {
  background: #983077;
  border-color: #983077;
  border-radius: 20px;
  font-size: 10px;
  text-transform: uppercase;
  cursor: text;
}

.key_demo_content .blue_btn {
  background: #4f9ebf;
  border-color: #4f9ebf;
  border-radius: 20px;
  font-size: 10px;
  text-transform: uppercase;
  cursor: text;
}

.key_demo_content .orange_btn {
  background: #f3b35f;
  border-color: #f3b35f;
  border-radius: 20px;
  font-size: 10px;
  text-transform: uppercase;
  cursor: text;
}

.partners_wrap .partners_content table tr th {
  background: #505050;
  color: #fff;
  border: 1px solid #fff;
  font-family: "BrandonGrotesque-Regular";
  font-size: 0.9rem;
  padding-left: 2%;
}

.people_count_content .table-bordered td,
.people_count_content .table-bordered th {
  border: 3px solid #fff;
  background: #f6f6f6;
}

.landingNewSec2bg {
  background: linear-gradient(0deg,
      rgba(241, 237, 235, 0.88),
      rgba(241, 237, 235, 0.73)),
    url(../images/mapBgsvg.svg);
  background-color: #f1edeb;
  background-size: contain;
}

.landingPagenewui .portalBanner {
  width: 76%;
  border: 2px solid #535353;
}

.landingPagenewui .portallogodiv2 {
  background-image: url(../images/coronamask.jpg);
  padding-bottom: 61%;
  height: 100%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-top: 8%;
}

.landingPagenewui .portallogodiv4 {
  background-image: url(../images/partners.png);
  padding-bottom: 61%;
  height: 100%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-top: 8%;
}


.landingPagenewui .portallogodiv3 {
  background-image: url(../images/resourceLibrary.png);
  padding-bottom: 56%;
  height: 100%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-top: 1%;
}

.landingPageFooter .sectiontitle {
  font-size: 1rem;
}

.landingPageFooter .db-description {
  width: 170px;
  font-size: 1rem;
  line-height: 1.5;
  color: #b6b5b4 !important;
}

.resourceholder.section1Dash {
  background-color: #f2eeec;
}

.back-arrow {
  position: absolute;
  left: 0;
  top: 0;
  padding: 0px 6px;
  border-radius: 6px;
  box-shadow: 0 2px 10px 0 rgb(0 0 0 / 20%);
  background: #f4f4f4;
  width: 38px;
  height: 38px;
}

.back-arrow img {
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.uploadTab {
  margin: 3px 5px;
  font-size: 12px;
  height: 40px;
  display: flex !important;
  align-items: center;
}

.resourceBannerImage {
  background: linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.9)),
    url(../images/librarypo.jpg);
  background-repeat: no-repeat;
  padding-bottom: 10%;
}

.resource-library-headertext {
  text-transform: uppercase;
  font-family: "BrandonGrotesque-Bold";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  margin: 0px;
  font-size: 3em;
}

.ResourcesPage .sidetext {
  background: #fff;
  position: relative;
  border-radius: 2px;
  font-size: 14px;
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
  margin-top: 0;
}

.side-resource-section .header-text {
  background: #f26432 !important;
  padding: 12px 12px;
}

.side-resource-section .header-text h2 {
  color: #fff;
  font-family: "Sentinel-Medium";
  font-size: 1.5rem;
  margin-bottom: 0;
}

.side-resource-section .text-component-div {
  background-color: #eaeaea !important;
  background: #eaeaea !important;
  padding: 20px;
}

.side-resource-section .sidebar-btn-grey {
  background: #eaeaea !important;
  color: #58524e !important;
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
  border: 4px solid #fff;
  border-radius: 30px;
  font-size: 14px;
  font-family: "Sentinel-Medium";
  text-transform: uppercase;
}

.side-resource-section .sidebar-btn-grey:hover {
  border: 4px solid #f26432;
}

.resources-side-card-title {
  background-color: #eaeaea !important;
  background: #eaeaea !important;
  color: #58524e !important;
  box-shadow: none !important;
  border-color: transparent !important;
  border-radius: 0 !important;
  font-family: "Sentinel-Medium";
  font-size: 20px;
  padding: 10px 12px !important;
}

.resource-content .side-resource-section .header-text {
  background-color: #eaeaea !important;
  color: #58524e !important;
  border-bottom: 2px solid #fff;
}

.resource-content .side-resource-section .header-text h2 {
  color: #58524e !important;
  font-size: 20px;
}

.resource-content .download-link {
  text-transform: uppercase !important;
  color: #fbb516 !important;
  font-family: "Sentinel-MediumItal";
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
}

.resource-content .description-div p {
  font-family: "Sentinel-Medium";
}

.downloadbtnicon {
  background-color: transparent;
  /* position: absolute; */
  right: 0;
}

.downloadbtnicon .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.cookies-footer {
  background: #262626;
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 1;
}

.cookies-footer .cookies-inside-content {
  display: flex;
  padding-top: 15px;
  padding-bottom: 15px;
  justify-content: center;
  align-items: center;
}

.cookies-footer .cookies-inside-content .cookies-text {
  color: #fff;
  font-family: "Sentinel-Medium";
  margin-bottom: 0;
}

.cookies-footer .cookies-inside-content a {
  font-family: BrandonGrotesque-Medium;
  color: #fff !important;
  text-transform: uppercase;
  margin-left: 3%;
  margin-right: 3%;
}

.cookies-footer .cookies-inside-content .cookies-btn {
  color: #fff !important;
  border: 2px solid #fff;
  border-radius: 20px;
  margin-right: 1%;
  padding: 6px 23px;
  font-size: 0.9rem;
  font-family: BrandonGrotesque-Medium;
}

.footer-sign-up {
  border: 2px solid #fff !important;
  color: #fff !important;
  padding: 10px 35px !important;
  font-size: 0.8125rem !important;
  text-transform: uppercase !important;
  cursor: pointer;
  font-family: BrandonGrotesque-Medium;
  border-radius: 20px !important;
}

.footer-sign-up:hover {
  background-color: #121212;
}

#footer .menu-engage-container,
footer .organization-container .menu-organization-container {
  font-family: BrandonGrotesque-Medium !important;
  text-transform: uppercase !important;
}

#footer .engage-container li,
footer .organization-container .menu-organization-container li {
  margin: 1rem 0 !important;
}

#footer .engage-container a,
footer .organization-container .menu-organization-container a {
  cursor: pointer !important;
}

#footer .engage-container a,
#footer .organization-container a {
  font-size: 0.875rem !important;
  letter-spacing: 1px !important;
  position: relative !important;
}

#footer .menu-engage-container .menu,
footer .organization-container .menu-organization-container .menu {
  list-style-type: none;
}

#footer .engage-container li,
footer .organization-container .menu-organization-container li {
  margin: 1rem 0;
}

#footer a,
#footer p {
  color: #b6b5b4;
}

#footer .engage-container .linkedin,
#footer .engage-container .twitter,
#footer .engage-container .youtube {
  display: inline-block;
  width: 20px;
  height: 20px;
  position: relative;
  margin: 0 0 1rem;
}

footer .engage-container .youtube:before {
  background: url(../images/youtube-logo-png-white2.png) 50%/100% auto no-repeat;
}

#footer .engage-container .twitter {
  margin-right: 0.5rem;
}

#footer .engage-container .youtube {
  margin-left: 0.5rem;
}

footer .engage-container .twitter:before {
  background: url(../images/twitter-brands-white.svg) 50%/100% auto no-repeat;
}

footer .engage-container .linkedin:before,
footer .engage-container .twitter:before {
  width: 20px;
  height: 20px;
  position: absolute;
  content: "";
}

footer .engage-container .linkedin:before {
  background: url(../images/linkedin-brands-square.svg) 50%/100% auto no-repeat;
}

footer .engage-container .linkedin:before,
footer .engage-container .twitter:before,
footer .engage-container .youtube:before
 {
  width: 20px;
  height: 20px;
  position: absolute;
  content: "";
}

footer .cookie-policy,
footer .terms-of-use {
  font-size: 0.75rem;
  line-height: 1.125rem;
  letter-spacing: 0.86px;
  text-decoration: underline;
  cursor: pointer;
}

footer .copyright {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-family: BrandonGrotesque-Regular;
}

/* sidebar popup css */
.sidebar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: hsla(0, 0%, 100%, 0.5);
  z-index: 2000;
}

.sidemodalpopup {
  z-index: 2000;
  top: 0;
}

.sidemodalpopup .sidebar-close-btn {
  background: transparent;
  position: absolute;
  right: 30px;
  width: auto;
  top: 30px;
  z-index: 2000;
  cursor: pointer;
}

.sidemodalpopup {
  background-color: #000;
  color: #fff;
}

.sidepopup-content-wrap .sidePopup-maintitle {
  font-family: Champion-HTF-Bantamweight;
  font-size: 4.5rem;
  line-height: 6rem;
  font-weight: 400;
  letter-spacing: 8.4px;
  color: #fff;
  text-transform: uppercase;
}

.sidepopup-content-wrap {
  position: relative;
  top: 3rem;
  margin: 0 2rem;
  padding: 2rem 0 4rem;
}

.sidepopup-content-wrap .sidePopup-content {
  max-width: 500px;
  margin: 1rem 0;
  line-height: 1.9rem;
  font-family: "Sentinel-Book";
  font-size: 1.2rem;
}

.sidepopup-content-wrap .content-wrap-sideDiv .content-title {
  color: #f26432;
  font-family: "BrandonGrotesque-Medium";
  font-size: 2.4rem;
  letter-spacing: 0.25px;
  margin-bottom: 1rem;
}

.show-modal-popup {
  display: block !important;
}

.hide-modal-popup {
  display: none !important;
}

/* faqs page css */
.faqsPage {
  background-color: #f3f4f7;
  color: #4c4d4f;
}

.faqsPage .faqs-title {
  color: #4c4d4f !important;
}

.faqsPage .faqs-title .orangeword {
  color: #f26432 !important;
}

.faqs-quest-section h3.quest-text:before {
  content: "";
  position: absolute;
  left: 15px;
  width: 0;
  height: 0;
  border-top: 0.8rem solid transparent;
  border-bottom: 0.8rem solid transparent;
  border-left: 1.1rem solid #e16d41;
}

.faqs-quest-section-content .quest-ans-div .quest-text {
  font-family: "BrandonGrotesque-Bold";
  color: #000 !important;
}

.faqs-quest-section-content .quest-ans-div .ans-text {
  font-family: "Sentinel-Book";
  color: #504d4c;
  font-size: 1.1rem !important;
}

.faqs-quest-section-content .top-heading-text {
  font-family: "BrandonGrotesque-Regular";
  color: #504d4c;
  font-size: 14px;
  margin-bottom: 2.6rem !important;
}

.faqs-quest-section-content .partial-underline:after {
  bottom: -6px;
}

.faqs-quest-section-content .quest-ans-div .ans-text ul {
  padding-left: 18px;
  margin-top: 20px;
}

@media screen and (max-width: 1375px) {
  .faqsPage .heading:before {
    left: -300px;
  }
}

span.ans-text-list {
  position: relative;
  left: 45px;
}

/* .faqs-navLink.nav-link {
  position: absolute;
  right: -80px;
} */

.feedbackIconDiv {
  position: fixed;
  bottom: 10px;
  z-index: 9999;
  right: 10px;
}

.appnavbar .nav-item.nav-active {
  border-top: 2px solid #f26432;
}

.CookieConsent div .cookies-strip-text {
  position: absolute;
  margin-bottom: 0;
  top: 20px;
  left: 206px;
  font-family: "Sentinel-Book";
  font-size: 1rem;
}

.CookieConsent div .cookies-strip-text a.cookie-policy {
  font-family: "BrandonGrotesque-Medium";
  color: #fff;
  margin-left: 12px;
  cursor: pointer;
}

#rcc-decline-button {
  border: 2px solid #ffffff !important;
  border-radius: 20px !important;
  font-family: "BrandonGrotesque-Medium";
  background-color: #353535 !important;
  padding: 8px 25px !important;
  position: relative;
  right: 170px;
  top: 0px;
}

#rcc-confirm-button {
  border: 2px solid #ffffff !important;
  border-radius: 20px !important;
  font-family: "BrandonGrotesque-Medium";
  color: #fff !important;
  background-color: #353535 !important;
  padding: 8px 25px !important;
  position: relative;
  right: 190px;
  top: 0px;
}

.program-app .highcharts-background {
  fill: #dfdfdf;
}

.sdgs_img_wrap {
  max-height: 192px;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
}

.sdgs_img_wrap>div {
  width: 31%;
  margin-bottom: 5px;
  margin-right: 5px;
}

.sdgs_img_wrap>div img.intervation_img {
  width: 100%;
}

.modalFormLabel {
  font-family: "BrandonGrotesque-Bold";
  font-size: 0.7875rem;
  text-transform: uppercase;
  letter-spacing: 1.02px;
}

.side_form_formContol {
  width: 90%;
  height: 50px !important;
  border-radius: 5px;
  border: 3px solid;
  margin: 0.3rem 0;
  padding: 0 1rem;
}

.sidebarLabelCheck {
  display: block;
  max-width: 370px;
  padding-left: 1.5rem;
  font-size: 0.85rem;
  line-height: 1.15rem;
  text-transform: none;
  letter-spacing: normal;
  font-family: Sentinel-Book;
}

.side_form_formContol.form-control.is-invalid,
.was-validated .side_form_formContol.form-control:invalid {
  background-image: none !important;
  border: 3px solid #ff0000 !important;
}

.sidebarErrorMessage {
  color: #ff0000 !important;
  font-family: Sentinel-Book !important;
  font-size: 1rem !important;
}

.overallErrorMessage {
  border: 3px solid #ff0000 !important;
  font-family: Sentinel-Book !important;
  color: #fff;
  padding: 0.2em 1em;
}

.overallThankYouMessage {
  border: 2px solid #398f14;
  font-family: Sentinel-Book !important;
  color: #fff;
  padding: 0.2em 1em;
}

select.side_form_formContol {
  width: 100%;
  background: url(../images/br_down_arrow.png) no-repeat right #fff;
  -webkit-appearance: none;
  background-position-x: 406px;
}

.loginPage {
  /* background-color: #f3f4f7; */
  background-color: #fff;
  /* height: 100vh; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.loginForm {
  max-width: 500px;
  margin: 0 auto;
  color: #fff;
  display: block;
  position: relative;
  margin-bottom: 40px;
  border-radius: 10px;
  /* background: rgb(199 199 199 / 34%); */
  box-shadow: 0 25px 20px -20px rgba(0, 0, 0, 0.1),
    0 0 15px rgba(193, 193, 193, 0.6);
  padding: 16px 18px 20px;
  background-color: #f3f4f7;
  background-image: url("../images/polygon21.svg");
  background-repeat: no-repeat;
  background-color: #313131;
}

.loginForm .login-card-title h3 {
  color: #fff;
  font-family: "BrandonGrotesque-Bold";
  font-size: 1.625rem;
  letter-spacing: 0.25px;
  margin-bottom: 1rem;
  text-transform: uppercase;
}

.loginForm .side_form_formContol {
  border: 2px solid rgb(0 0 0 / 16%);
  border-radius: 20px;
  height: 45px !important;
  width: 100%;
}

.loginForm .side_form_formContol::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #939291;
  font-family: "Sentinel-MediumItal";
}

.loginForm .side_form_formContol::-moz-placeholder {
  /* Firefox 19+ */
  color: #939291;
  font-family: "Sentinel-MediumItal";
}

.loginForm .side_form_formContol:-ms-input-placeholder {
  /* IE 10+ */
  color: #939291;
  font-family: "Sentinel-MediumItal";
}

.loginForm .side_form_formContol:-moz-placeholder {
  /* Firefox 18- */
  color: #939291;
  font-family: "Sentinel-MediumItal";
}

.loginForm .explorealltempbtn {
  background: #ebf2fe !important;
  color: #000 !important;
  border-color: #ebf2fe;
}

.greySection .catchment_area_div {
  padding: 12px 12px;
  margin-top: 3rem;
  height: 400px;
  overflow: auto;
}

.pdf_download_alert .alert {
  border-radius: 0;
  background-color: #f3f4f7;
  border-color: #f3f4f7;
  font-family: Sentinel-Book !important;
}

.pdf_download_alert {
  position: sticky;
  top: 46px;
  z-index: 999;
  width: 100%;
}

.pdf_download_alert .alert .spinner-border {
  width: 1rem;
  height: 1rem;
  border-width: 0.15em;
  margin-right: 3px;
}

.catchment_area_div .catchment_area_content div div p {
  color: #504d4c !important;
  margin-bottom: 8px;
}

.partners_wrap .partners_content .react-bootstrap-table .table-bordered td {
  font-family: "Sentinel-Light";
  font-size: 14px;
}

.content-top-gap {
  /* margin-top: 60px; */
  padding: 20px;
  background: #eff1f9;
  height: 100%;
}

.uploadDataPage {
  font-family: "NunitoSans-Regular" !important;
  color: #5d6b71;
}

.uploadDataPage .nav-tabs {
  background: #333;
}

.uploadDataPage .tab-content {
  background-color: #fff;
  padding: 12px;
}

.uploadDataPage .nav-tabs .nav-item.show .nav-link,
.uploadDataPage .nav-tabs .nav-link.active {
  color: #fff;
  background-color: #f26432;
  border-color: #f26432;
  border-radius: 0;
}

.uploadDataPage .nav-tabs .nav-link {
  margin-bottom: -1px;
  border: 1px solid #333333;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  color: #fff;
  cursor: pointer;
}

.uploadDataPage .nav-tabs {
  border-bottom: 1px solid #333333;
}

.uploadDataPage .tabTitle {
  font-family: "NunitoSans-Regular";
  color: #5d6b71;
}

.uploadDataPage select.form-control {
  border: 1px solid #ced4da !important;
}

.uploadDataPage label {
  color: #121212;
  font-size: 1.1rem;
  font-family: "NunitoSans-Regular";
}

.program_div_inside {
  max-height: 300px;
  overflow: hidden;
  overflow-y: scroll;
  padding-right: 10px;
}

.uploadDataPage select.form-control,
.modal .form-control#userRole,
.modal .form-control#userMember {
  width: 100%;
  background: url(../images/br_down_arrow.png) no-repeat #fff;
  -webkit-appearance: none;
  background-position: 97% center;
  background-size: 11px;
}

.program_strat_div_p {
  font-family: "BrandonGrotesque-Medium";
  margin-bottom: 0;
  font-size: 16px;
  color: #504d4c !important;
}

.appbarDropDown .dropdown-menu {
  border-radius: 0;
  border-top: 2px solid #f26432;
  width: 250px;
  top: 73px;
  z-index: 1002;
  -webkit-box-shadow: 3px 5px 5px 0 rgb(0 0 0 / 10%);
  box-shadow: 3px 5px 5px 0 rgb(0 0 0 / 10%);
  /* max-height: 1000px; */
  transition: 0.5s ease-in-out;
}

.appbarDropDown .dropdown-menu .dropdown-item {
  font-family: "BrandonGrotesque-Medium";
  text-transform: uppercase;
  font-size: 0.9rem;
  padding: 12px 12px 12px 25px;
  letter-spacing: 0.88px;
}

.appbarDropDown .dropdown-menu .dropdown-item:focus,
.appbarDropDown .dropdown-menu .dropdown-item:hover {
  background-color: #fff3e1;
  color: #212529;
}

.appbarDropDown:hover .dropdown-menu {
  display: block !important;
  /* transform: translateY(50%) rotate(180deg); */
}

.appbarDropDown .dropdown-toggle {
  position: relative;
}

.appbarDropDown .dropdown-toggle::before {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  border-top: 0;
  border-right: 0;
  border-bottom: 0;
  border-left: 0;
  content: "^";
  color: #f26432;
  position: absolute;
  font-size: 22px;
  top: -3px;
  right: -12px;
  transform: rotate(180deg);
  transition: all 1s;
}

.appbarDropDown .dropdown-toggle::after {
  display: none;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  border-top: 0;
  border-right: 0;
  border-bottom: 0;
  border-left: 0;
  content: "^";
  color: #f26432;
  position: absolute;
  font-size: 22px;
  top: 6px;
  right: -12px;
  transform: rotate(0deg);
  transition: all 1s;
}

.appbarDropDown:hover .dropdown-toggle::before {
  display: none;
}

.appbarDropDown:hover .dropdown-toggle::after {
  display: block;
}

.appbarDropDown .nav-item {
  padding: 0;
  margin-left: 0;
}

.full_summary_height_pdf {
  height: 100% !important;
  max-height: 100% !important;
  overflow: auto !important;
}

/* not found page css */
#notfound {
  position: relative;
  height: 70vh;
}

#notfound .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.notfound {
  max-width: 520px;
  width: 100%;
  line-height: 1.4;
  text-align: center;
}

.notfound .notfound-404 {
  position: relative;
  height: 200px;
  margin: 0 auto 20px;
  z-index: -1;
}

.notfound .notfound-404 h1 {
  font-family: "BrandonGrotesque-Regular";
  font-size: 236px;
  font-weight: 200;
  margin: 0;
  color: #211b19;
  text-transform: uppercase;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.notfound .notfound-404 h2 {
  font-family: "BrandonGrotesque-Regular";
  font-size: 28px;
  font-weight: 400;
  text-transform: uppercase;
  color: #211b19;
  background: #fff;
  padding: 10px 5px;
  margin: auto;
  display: inline-block;
  position: absolute;
  bottom: 5px;
  left: 0;
  right: 0;
}

.notfound a {
  font-family: "BrandonGrotesque-Medium";
  display: inline-block;
  font-weight: 700;
  text-decoration: none;
  color: #fff;
  text-transform: uppercase;
  padding: 13px 23px;
  background: #f26432;
  font-size: 18px;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

.notfound a:hover {
  color: #fff;
  background: #f26432;
}

.modal {
  color: #212529;
}

.modal .modal-title {
  font-weight: 600;
  margin-top: 0;
}

.modal .form-control {
  border: 1px solid #ced4da !important;
}

.tab-content>.active {
  color: #121212;
}

@media only screen and (max-width: 767px) {
  .notfound .notfound-404 h1 {
    font-size: 148px;
  }
}

@media only screen and (max-width: 480px) {
  .notfound .notfound-404 {
    height: 148px;
    margin: 0 auto 10px;
  }

  .notfound .notfound-404 h1 {
    font-size: 86px;
  }

  .notfound .notfound-404 h2 {
    font-size: 16px;
  }

  .notfound a {
    padding: 7px 15px;
    font-size: 14px;
  }
}

/* Overview Dashboard Page style starts */

.overview_dashboard_page .nav-tabs {
  border-bottom: none;
  justify-content: space-between;
  margin-top: 0px;
}

.overview_dashboard_page .tab-content {
  margin-bottom: 0px;
}

/* .overview_dashboard_page .nav-tabs .nav-link.active{
  border: none;
  background-color: #015D7B;
  border-radius: 0px;
  padding: 20px 10px 20px 10px;
  position: relative;
} */

.overview_dashboard_page .nav-tabs .nav-item.active {
  border: none;
  background-color: #015d7b;
  border-radius: 0px;
  padding: 20px 10px 20px 10px;
  position: relative;
}

.overview_dashboard_page .active_arrow_holder {
  display: none;
}

.overview_dashboard_page .nav-tabs .nav-item.active .active_arrow_holder {
  display: block;
}

.overview_dashboard_page .nav-tabs .nav-item.active .active_arrow_holder p {
  content: "";
  width: 0;
  height: 0;
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
  display: block;
  margin: auto;
  border-top: 15px solid #015d7b;
}

.overview_dashboard_page .nav-tabs .nav-item.active .active_arrow_holder {
  position: absolute;
  margin-top: 20px;
  width: 95%;
}

.overview_dashboard_page .nav-tabs .nav-link {
  border: none;
  background-color: #fff;
  border-radius: 0px;
  padding: 0;
}

.overview_dashboard_page .nav-tabs .nav-item {
  width: 19%;
  padding: 20px 10px 20px 10px;
  border: none;
  transition: 0.3s linear;
}

.overview_dashboard_page .nav-tabs .dropdown-list {
  position: relative;
  z-index: 1000;
  box-shadow: 6px 3px 7px #00000080;
}

.overview_dashboard_page .dropdown-list .filter-dropdown-control.dropdown>p {
  margin: 0;
  cursor: pointer;
}

.overview_dashboard_page .nav-tabs .nav-item .nav-link div.header_nav_tabholder_container {
  background: transparent linear-gradient(129deg, #ffffff 0%, #eae9e5 100%) 0% 0% no-repeat padding-box;
  box-shadow: 6px 3px 7px #00000080;
}

.overview_dashboard_page .nav-tabs .nav-item .nav-link div.header_nav_tabholder_container:hover {
  background: transparent linear-gradient(129deg, #ffffff 0%, #eae9e5 100%) 0% 0% no-repeat padding-box;
  box-shadow: none !important;
}

.overview_dashboard_page .nav-tabs .nav-link:focus,
.overview_dashboard_page .nav-tabs .nav-link:hover {
  border: none;
}

.overview_dashboard_page .nav-tabs .nav-link {
  margin-bottom: 0px;
  border: none;
}

.overview_dashboard_page .filter-title {
  text-align: left;
  font-style: normal;
  font-weight: normal;
  /*font-size: 12px;*/
  font-size: 0.8rem;
  /*line-height: 18px;*/
  line-height: 1.2rem;
  letter-spacing: 0px;
  color: #3e3c3b;
  font-family: "BrandonGrotesque-Regular";
  text-transform: uppercase;
  margin-bottom: 0px;
  padding: 5px 10px;
}

.overview_dashboard_page .filter-image-holder {
  height: 70px;
  width: 70px;
  background: #343332 0% 0% no-repeat padding-box;
  box-shadow: -10px 3px 6px #00000029;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overview_dashboard_page .filter-image-holder img {
  height: 40px;
  width: 40px;
}

.overview_dashboard_page .filter-img-description {
  text-align: right;
  font-style: normal;
  font-weight: normal;
  /*font-size: 80px;*/
  font-size: 5.333rem;
  font-size: 3.867rem;
  /*line-height: 50px;*/
  line-height: 3.333rem;
  letter-spacing: 0px;
  color: #015d7b;
  font-family: Champion-HTF-Bantamweight;
}

.overview_dashboard_page .filter-img-description span {
  /* font-size: 20px;*/
  font-size: 1.333rem;
  /*line-height: 20px;*/
  line-height: 1.333rem;
}

.overview_dashboard_page .filter-dropdown-control {
  text-align: center;
  background: #3e3c3b 0% 0% no-repeat padding-box;
  color: #fff;
}

.overview_dashboard_page .filter_img_text_holder {
  padding: 5px 10px;
}

.overview_dashboard_page .fullwidth_tabsbg {
  background: #343332 0% 0% no-repeat padding-box;
  box-shadow: inset 0px 3px 6px #00000029;
  padding-top: 25px;
  padding-bottom: 25px;
}

.overview_dashboard_page .header_nav_tabholder_container.member_programs:hover {
  cursor: pointer;
  /*background: transparent linear-gradient(129deg, #FFFFFF 0%, #EAE9E5 100%) 0% 0% no-repeat padding-box;
box-shadow: 6px 3px 7px #00000080;*/
}

.overview_dashboard_page .header_nav_tabholder_container.member_programs:hover .filter-image-holder {
  background: #c41111 0% 0% no-repeat padding-box;
}

.overview_dashboard_page .header_nav_tabholder_container.member_programs:hover .filter-img-description {
  color: #c41111;
}

.overview_dashboard_page .header_nav_tabholder_container.bene_population:hover {
  cursor: pointer;
  /*background: transparent linear-gradient(129deg, #FFFFFF 0%, #EAE9E5 100%) 0% 0% no-repeat padding-box;
box-shadow: 6px 3px 7px #00000080;*/
}

.overview_dashboard_page .header_nav_tabholder_container.bene_population:hover .filter-image-holder {
  background: #930c62 0% 0% no-repeat padding-box;
}

.overview_dashboard_page .header_nav_tabholder_container.bene_population:hover .filter-img-description {
  color: #930c62;
}

.overview_dashboard_page .header_nav_tabholder_container.member_companies:hover {
  cursor: pointer;
  background: transparent linear-gradient(129deg, #ffffff 0%, #eae9e5 100%) 0% 0% no-repeat padding-box;
  box-shadow: 6px 3px 7px #00000080;
}

.overview_dashboard_page .header_nav_tabholder_container.member_companies:hover .filter-image-holder {
  background: #0b7451 0% 0% no-repeat padding-box;
}

.overview_dashboard_page .header_nav_tabholder_container.member_companies:hover .filter-img-description {
  color: #0b7451;
}

.overview_dashboard_page .header_nav_tabholder_container.add_ncds:hover {
  cursor: pointer;
  /* background: transparent linear-gradient(129deg, #FFFFFF 0%, #EAE9E5 100%) 0% 0% no-repeat padding-box;
box-shadow: 6px 3px 7px #00000080;*/
}

.overview_dashboard_page .header_nav_tabholder_container.add_ncds:hover .filter-image-holder {
  background: #ffba18 0% 0% no-repeat padding-box;
}

.overview_dashboard_page .header_nav_tabholder_container.add_ncds:hover .filter-img-description {
  color: #ffba18;
}

.overview_dashboard_page .header_nav_tabholder_container.lmi_country:hover {
  cursor: pointer;
  /*background: transparent linear-gradient(129deg, #FFFFFF 0%, #EAE9E5 100%) 0% 0% no-repeat padding-box;
box-shadow: 6px 3px 7px #00000080;*/
}

.overview_dashboard_page .header_nav_tabholder_container.lmi_country:hover .filter-image-holder {
  background: #f26432 0% 0% no-repeat padding-box;
}

.overview_dashboard_page .header_nav_tabholder_container.lmi_country:hover .filter-img-description {
  color: #f26432;
}

.overview_dashboard_page .map-holder {
  height: 500px;
  text-align: center;
}

.overview_dashboard_page .map-holder img {
  width: 100%;
  margin: 0 auto;
  height: 490px;
}

.overview_dashboard_page .chart-title {
  text-align: left;
  font-style: normal;
  font-weight: bold;
  /*font-size: 15px;*/
  font-size: 1rem;
  /*line-height: 29px;*/
  line-height: 1.933rem;
  letter-spacing: 0px;
  color: #fff;
  text-transform: uppercase;
  font-family: BrandonGrotesque-Medium;
}

.overview_dashboard_page .chart-holder {
  background: #343332;
  min-height: 250px;
  color: #fff;
}

.overview_dashboard_page .analytical-db-link {
  font-style: normal;
  font-weight: normal;
  /*font-size: 16px;*/
  font-size: 1.067rem;
  /*line-height: 50px;*/
  line-height: 3.333rem;
  letter-spacing: 0px;
  color: #343332;
  text-transform: uppercase;
  font-family: BrandonGrotesque-Regular;
}

.overview_dashboard_page .analytical-db-link img {
  margin-right: 8px;
  margin-top: -5px;
}

.overview_dashboard_page .member_profile_card_image {
  height: 200px;
  width: 200px;
  margin-top: 5px !important;
  margin: 0 auto;
  transition: transform 0.2s;
}

.overview_dashboard_page .member_profile_card_image:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.overview_dashboard_page .bg_member_profile {
  background-image: url("../images/Abundant_Health.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.overview_dashboard_page .bg_member_profile.no_bgimg {
  background-image: none;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.overview_dashboard_page .bg_member_profile.no_bgimg .member_logo_bgimg {
  background-image: url("../images/Astellas.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100px;
  position: absolute;
  top: 5px;
  width: 200px;
}

.overview_dashboard_page .bg_member_profile.no_bgimg .member_profile_description {
  background-color: #fff;
}

.overview_dashboard_page .bg_member_profile.no_bgimg .member_profile_ooimage {
  position: relative;
  z-index: 9;
  right: 0px;
}

.overview_dashboard_page .member_profile_ooimage {
  padding-top: 0px;
  padding-bottom: 0px;
  text-align: right;
  padding-right: 0px;
}

.overview_dashboard_page .member_profile_ooimage img {
  margin-left: auto;
}

.overview_dashboard_page .member_profile_description {
  height: 100px;
  /*background-color: rgba(255,255,255,0.6);*/
  position: absolute;
  bottom: 5px;
  width: 200px;
  background: rgba(255, 255, 255, 0.4);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);
}

.overview_dashboard_page .member_profile_title p {
  text-align: left;
  font-style: normal;
  font-weight: normal;
  /*font-size: 16px;*/
  font-size: 1.067rem;
  /*line-height: 25px;*/
  line-height: 1.667rem;
  font-family: BrandonGrotesque-Medium;
  letter-spacing: 0px;
  color: #1a1a1a;
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 0px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.overview_dashboard_page .member_profile_title {
  height: 50px;
}

.overview_dashboard_page .member_profile_company_name p {
  text-align: left;
  font-style: normal;
  font-weight: normal;
  /*font-size: 15px;*/
  font-size: 1rem;
  /*line-height: 25px;*/
  line-height: 1.667rem;
  letter-spacing: 0px;
  color: #c41111;
  font-family: BrandonGrotesque-Regular;
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 0px;
  display: inline-block;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.overview_dashboard_page .member_profile_company_name {
  height: 50px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}

.overview_dashboard_page .member_companies_card {
  width: 200px;
  height: 170px;
  background: #1a1a1a 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  margin-top: 5px !important;
  margin: 0 auto;
}

.member_companies_card img {
  height: auto;
  max-width: 80%;
  margin: 0 auto;
  padding-top: 10px;
  padding-bottom: 10px;
  max-height: 70px;
}

.member_companies_card_description {
  height: 100px;
  /*border-top: 1px solid #35B4BD;*/
  padding-top: 0px;
  padding-bottom: 5px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.member_companies_card_description .title {
  text-align: left;
  font-style: normal;
  font-weight: normal;
  /*font-size: 16px;*/
  font-size: 1.067rem;
  /*line-height: 25px;*/
  line-height: 1.667rem;
  letter-spacing: 0px;
  color: #35b4bd;
  font-family: BrandonGrotesque-Regular;
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 0px;
}

.member_companies_card_description .subtitle {
  text-align: left;
  font-style: normal;
  font-weight: normal;
  /*font-size: 16px;*/
  font-size: 1.067rem;
  /*line-height: 25px;*/
  line-height: 1.667rem;
  letter-spacing: 0px;
  color: #ffffff;
  font-family: BrandonGrotesque-Regular;
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 0px;
}

.member_profile_open_image {
  background-image: url("../images/Abundant_Health.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 300px;
}

.member_profile_card_open {
  padding: 20px 10px;
}

.member_profile_card_open_ooimage {
  position: relative;
}

.member_profile_card_open_ooimage img {
  z-index: 9;
  position: relative;
}

.aotext {
  background: #343332 0% 0% no-repeat padding-box;
  color: #fff;
  height: 40px;
  width: fit-content;
  position: absolute;
  right: 40px;
  text-align: right;
  top: 6px;
  margin-left: auto;
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  transition: right 0.8s linear;
  right: 25px;
  width: 0px;
  opacity: 0;
}

.member_profile_card_open_ooimage:hover .aotext {
  right: 38px;
  width: fit-content;
  opacity: 1;
  transition: right 0.8s linear;
}

.aotext p {
  text-align: left;
  font-style: normal;
  font-weight: 300;
  /*font-size: 11px;*/
  font-size: 0.733rem;
  /*line-height: 12px;*/
  line-height: 0.8rem;
  letter-spacing: 0px;
  color: #ffffff;
  font-family: "Sentinel-Medium";
  margin-bottom: 0px;
}

.member_profile_card_open_cat_title {
  text-align: left;
  font-style: normal;
  font-weight: 300;
  /*font-size: 13px;*/
  font-size: 0.867rem;
  /*line-height: 15px;*/
  line-height: 1rem;
  letter-spacing: 0px;
  color: #f0f0f0;
  font-family: "Sentinel-Medium";
  margin-bottom: 0px;
}

.catimg {
  background-color: #015d7b;
  width: 30px;
  height: 30px;
  padding: 5px;
  border-radius: 50%;
}

.catimg.orange {
  background-color: #f26432;
}

.catimg.white {
  background-color: #fff;
}

.member_profile_card_open_cat_title a {
  color: #35b4bd !important;
  text-transform: capitalize;
  text-decoration: underline !important;
}

.prog_ended_date {
  text-align: left;
  font-style: normal;
  font-weight: normal;
  /*font-size: 8px;*/
  font-size: 0.533rem;
  /*line-height: 25px;*/
  line-height: 1.667rem;
  letter-spacing: 0px;
  color: #ffba18;
  font-family: BrandonGrotesque-Medium;
  margin-bottom: 0px;
}

.prog_title_company_name {
  text-align: left;
  font-style: normal;
  font-weight: 500;
  /*font-size: 12px;*/
  font-size: 0.8rem;
  /*line-height: 25px;*/
  line-height: 1.667rem;
  letter-spacing: 0px;
  color: #fff;
  font-family: BrandonGrotesque-Medium;
}

.prog_title_company_name span {
  color: #35b4bd;
}

.member_desc_text {
  /* height: 100px; */
  max-height: 260px;
  overflow-y: auto;
  padding-right: 5px;
}

.member_desc_text p,
.member_desc_text span,
.member_desc_text li {
  text-align: left;
  letter-spacing: 0px;
  color: #ffffff;
  font-family: "Sentinel-Light";
  font-style: normal;
  font-weight: 200;
  /*font-size: 13px;*/
  font-size: 0.867rem;
  /*line-height: 17px;*/
  line-height: 1.133rem;
}

.seeprog_link {
  text-align: left;
  font-style: normal;
  font-weight: 500;
  /*font-size: 11px;*/
  font-size: 0.733rem;
  /*line-height: 16px;*/
  line-height: 1.067rem;
  letter-spacing: 0px;
  color: #ffba18;
  font-family: BrandonGrotesque-Medium;
  text-transform: uppercase;
}

.seeprog_link img {
  height: 10px;
  margin-right: 5px;
}

.member_profile_card_closebtn {
  position: absolute;
  right: 0px;
  top: -10px;
  z-index: 9;
}

.member_profile_card_closebtn img {
  width: 30px;
}

.w-6px {
  width: 6px;
}

.overview_dashboard_page .slick-prev {
  left: -80px !important;
}

.overview_dashboard_page .slick-next {
  right: -80px !important;
}

.member_companies_card_description:before {
  content: "";
  background: url("../images/blue_border_image_small.svg");
  width: 190px;
  height: 3px;
  display: block;
  margin-left: 5px;
  margin-bottom: 5px;
}

.blue_border_holder {
  background-image: url("../images/blue_border_image.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 3px;
  margin-top: 2px;
}

.overview_dashboard_page .slick-prev:before {
  content: "" !important;
  background: url("../images/slider_arrow_left.svg");
  height: 94px;
  width: 29px;
  display: block;
}

.overview_dashboard_page .slick-next:before {
  content: "" !important;
  background: url("../images/slider_arrow_right.svg");
  height: 94px;
  width: 29px;
  display: block;
}

.overview_dashboard_page .slick-prev,
.overview_dashboard_page .slick-next {
  height: 94px;
  width: 30px;
}

.filter-dropdown-control.dropdown .dropdown_arrow {
  height: 24px;
  transform: rotate(90deg);
}

.filter-dropdown-control .dropdown-menu {
  width: 100%;
  background-color: #1a1a1a;
  color: #fff;
  padding-bottom: 0px;
}

.filter-dropdown-control .dropdown-menu form {
  min-height: auto !important;
}

.filter-dropdown-control .dropdown-menu form .searchholder i {
  position: absolute;
  top: 15px;
  left: 5px;
}

.filter-dropdown-control .dropdown-menu form .searchholder input {
  background-color: #707070;
  border: none;
  border-radius: 0px;
  border-bottom: 1px solid #fff;
  letter-spacing: 0px;
  color: #ffffff;
  text-align: left;
  font-family: "Sentinel-Light";
  font-style: italic;
  font-weight: 300;
  /*font-size: 13px;*/
  font-size: 0.867rem;
  /*line-height: 15px;*/
  line-height: 1rem;
  padding-left: 30px;
}

.form-control:focus {
  box-shadow: none !important;
}

.prog_list_holder_main {
  height: 200px;
  overflow-y: auto;
}

.prog_list_holder_main p {
  background-color: #707070;
  text-align: left;
  letter-spacing: 0px;
  color: #ffffff;
  font-style: normal;
  font-weight: normal;
  /*font-size: 13px;*/
  font-size: 0.867rem;
  /*line-height: 25px;*/
  line-height: 1.667rem;
  font-family: "BrandonGrotesque-Regular";
  padding-left: 5px;
  padding-right: 5px;
}

.prog_list_holder_main p:hover {
  background-color: #1a1a1a;
  cursor: pointer;
}

.member_programs_dropdown_uparrow_holder {
  background-color: #c41111;
  text-align: center;
  cursor: pointer;
}

.bene_population_dropdown_uparrow_holder {
  background-color: #930c62;
  text-align: center;
  cursor: pointer;
}

.add_ncds_dropdown_uparrow_holder {
  background-color: #ffba18;
  text-align: center;
  cursor: pointer;
}

.lmi_countries_dropdown_uparrow_holder {
  background-color: #f26432;
  text-align: center;
  cursor: pointer;
}

.member_companies_dropdown_uparrow_holder {
  background-color: #0b7451;
  text-align: center;
  cursor: pointer;
}

.member_programs_dropdown_uparrow_holder .dropdown_uparrow,
.bene_population_dropdown_uparrow_holder .dropdown_uparrow,
.add_ncds_dropdown_uparrow_holder .dropdown_uparrow,
.lmi_countries_dropdown_uparrow_holder .dropdown_uparrow,
.member_companies_dropdown_uparrow_holder .dropdown_uparrow {
  height: 24px;
  transform: rotate(270deg) !important;
}

.filter-dropdown-control .dropdown-menu form .searchholder input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #939291;
  font-family: "Sentinel-MediumItal";
}

.filter-dropdown-control .dropdown-menu form .searchholder input::-moz-placeholder {
  /* Firefox 19+ */
  color: #939291;
  font-family: "Sentinel-MediumItal";
}

.filter-dropdown-control .dropdown-menu form .searchholder input:-ms-input-placeholder {
  /* IE 10+ */
  color: #939291;
  font-family: "Sentinel-MediumItal";
}

.filter-dropdown-control .dropdown-menu form .searchholder input:-moz-placeholder {
  /* Firefox 18- */
  color: #939291;
  font-family: "Sentinel-MediumItal";
}

.accordion_list_holder .card {
  background-color: #707070 !important;
  margin-bottom: 10px !important;
  border-radius: 0px !important;
}

.accordion_list_holder .card .card-body {
  padding: 0px 10px !important;
}

.accordion_list_holder label {
  font-size: 13px !important;
  font-size: 0.867rem !important;
}

.accordion_list_holder .form-check-input {
  margin-top: 0.1rem;
}

.sidepopup-content-wrap .form-check-input {
  width: 14px;
  height: 18px;
}

.border-right-grey {
  border-right: 1px solid #1a1a1a;
}

.prog_list_holder_main.member_company_list .row {
  background-color: #707070;
}

.prog_list_holder_main.member_company_list .row p {
  background-color: transparent;
}

.prog_list_holder_main .row:hover {
  background-color: #1a1a1a;
}

.filter-dropdown-control .dropdown-menu {
  display: block;
  max-height: 0;
  visibility: hidden;
  transition: all 0.4s ease-in-out;
  overflow: hidden;
}

.filter-dropdown-control .show.dropdown-menu {
  max-height: inherit;
  visibility: visible;
  transition: all 0.4s ease-in-out;
  top: -24px;
}

.filter-dropdown-control .show.dropdown-menu>.row {
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.prog_list_holder_main .accordion_list_holder p {
  display: flex;
  align-items: center;
}

.accordion_list_holder .parentNCDCheckbox,
.accordion_list_holder .parentBeneCheckbox,
.accordion_list_holder .parentCountryCheckbox {
  display: inline-block;
  position: static;
  margin: 3px 3px;
}

.add_ncd_card {
  height: 200px;
  width: 200px;
  margin-top: 5px !important;
  margin: 0 auto;
  transition: transform 0.2s;
}

.add_ncd_card:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.add_ncd_card.cancer_card {
  background: #250319 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #930c62;
}

.add_ncd_card_titleholder p {
  text-align: center;
  letter-spacing: 0px;
  color: #ffffff;
  font-style: normal;
  /*font-size: 22px;*/
  font-size: 1.467rem;
  /*line-height: 41px;*/
  line-height: 2.733rem;
  font-family: "BrandonGrotesque-Regular";
  margin-bottom: 0px;
  font-weight: 100;
  padding: 7px 5px;
  background-color: rgba(0, 0, 0, 0.5);
}

.add_ncd_card_descriptionholder .cat_title {
  text-align: right;
  font-style: normal;
  font-weight: normal;
  /*font-size: 16px;*/
  font-size: 1.067rem;
  /*line-height: 22px;*/
  line-height: 1.467rem;
  font-family: "BrandonGrotesque-Regular";
  letter-spacing: 0px;
  color: #ffffff;
  margin-bottom: 0px;
  padding: 5px;
}

.add_ncd_card_descriptionholder .cat_subtitle {
  text-align: left;
  font-style: normal;
  font-weight: normal;
  /*font-size: 16px;*/
  font-size: 1.067rem;
  /*line-height: 22px;*/
  line-height: 1.467rem;
  font-family: "BrandonGrotesque-Medium";
  letter-spacing: 0px;
  color: #ffffff;
  margin-bottom: 0px;
  padding: 5px;
}

.add_ncd_card.cancer_card .add_ncd_card_descriptionholder {
  height: 145px;
  background: linear-gradient(0deg,
      rgba(147, 12, 98, 0.1),
      rgba(147, 12, 98, 0.1)),
    url(../images/cancer_purple_light.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.add_ncd_card.cancer_card:hover .add_ncd_card_descriptionholder {
  height: 145px;
  background: linear-gradient(0deg,
      rgba(147, 12, 98, 0.3),
      rgba(147, 12, 98, 0.3)),
    url(../images/cancer_purple_dark.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.add_ncd_card.cvd_card {
  background: #2b0303 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #c41111;
}

.add_ncd_card.cvd_card .add_ncd_card_descriptionholder {
  height: 145px;
  background: linear-gradient(0deg,
      rgba(196, 17, 17, 0.1),
      rgba(196, 17, 17, 0.1)),
    url(../images/cvd_light.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.add_ncd_card.cvd_card:hover .add_ncd_card_descriptionholder {
  height: 145px;
  background: linear-gradient(0deg,
      rgba(196, 17, 17, 0.3),
      rgba(196, 17, 17, 0.3)),
    url(../images/cvd_dark.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.add_ncd_card.diabetes_card {
  background: #3e2b00 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #ffba18;
}

.add_ncd_card.diabetes_card .add_ncd_card_descriptionholder {
  height: 145px;
  background: linear-gradient(0deg,
      rgba(255, 186, 24, 0.1),
      rgba(255, 186, 24, 0.1)),
    url(../images/diab_light.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.add_ncd_card.diabetes_card:hover .add_ncd_card_descriptionholder {
  height: 145px;
  background: linear-gradient(0deg,
      rgba(255, 186, 24, 0.3),
      rgba(255, 186, 24, 0.3)),
    url(../images/diab_dark.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.add_ncd_card.resp_card {
  background: #01212e 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #015c84;
}

.add_ncd_card.resp_card .add_ncd_card_descriptionholder {
  height: 145px;
  background: linear-gradient(0deg,
      rgba(1, 92, 132, 0.1),
      rgba(1, 92, 132, 0.1)),
    url(../images/resp_light.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.add_ncd_card.resp_card:hover .add_ncd_card_descriptionholder {
  height: 145px;
  background: linear-gradient(0deg,
      rgba(1, 92, 132, 0.3),
      rgba(1, 92, 132, 0.3)),
    url(../images/resp_dark.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.add_ncd_card.other_card {
  background: #0d1c09 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #52b335;
}

.add_ncd_card.other_card .add_ncd_card_descriptionholder {
  height: 145px;
  background: linear-gradient(0deg,
      rgba(82, 179, 53, 0.1),
      rgba(82, 179, 53, 0.1)),
    url(../images/other_light.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-position: 25% 160%;
}

.add_ncd_card.other_card:hover .add_ncd_card_descriptionholder {
  height: 145px;
  background: linear-gradient(0deg,
      rgba(82, 179, 53, 0.3),
      rgba(82, 179, 53, 0.3)),
    url(../images/other_dark.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-position: 25% 160%;
}

.add_ncd_card_descriptionholder:before {
  content: "";
  background: url("../images/white_border_image_small.svg");
  width: 190px;
  height: 3px;
  display: block;
  margin-left: 5px;
  margin-bottom: 5px;
}

.lmi_countries_card {
  /* height: 150px; */
  height: 180px;
  width: 200px;
  margin-top: 5px !important;
  margin: 0 auto;
  transition: transform 0.2s;
  /* background: transparent linear-gradient(270deg, #1A1A1A00 0%, #0C0C0CB7 35%, #161616 100%) 0% 0% no-repeat padding-box;
  background:linear-gradient(270deg, #1A1A1A00 0%, #0C0C0CB7 35%, #161616 100%), url(../images/afg_flag.png); */
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.lmi_countries_card:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.lmi_contries_card_titleholder p {
  text-align: center;
  letter-spacing: 0px;
  color: #ffffff;
  font-style: normal;
  /*font-size: 19px;*/
  font-size: 1.267rem;
  /*line-height: 25px;*/
  line-height: 1.667rem;
  font-family: "BrandonGrotesque-Bold";
  margin-bottom: 0px;
  font-weight: 100;
  padding: 15px 5px;
  padding-top: 0px;
}

.lmi_countries_card_descriptionholder {
  /*height: 145px;*/
}

.lmi_countries_card_descriptionholder:before {
  content: "";
  background: url("../images/white_border_image_small.svg");
  width: 190px;
  height: 3px;
  display: block;
  margin-left: 5px;
  margin-bottom: 5px;
}

.lmi_countries_card_descriptionholder .cat_title {
  text-align: left;
  font-style: normal;
  font-weight: normal;
  /*font-size: 16px;*/
  font-size: 1.067rem;
  /*line-height: 25px;*/
  line-height: 1.667rem;
  font-family: "BrandonGrotesque-Regular";
  letter-spacing: 0px;
  color: #ffffff;
  margin-bottom: 0px;
  padding: 5px;
  padding-left: 12px;
}

.lmi_countries_card_descriptionholder .cat_subtitle {
  text-align: left;
  font-style: normal;
  font-weight: normal;
  /*font-size: 16px;*/
  font-size: 1.067rem;
  /*line-height: 25px;*/
  line-height: 1.667rem;
  font-family: "BrandonGrotesque-Regular";
  letter-spacing: 0px;
  color: #ffffff;
  margin-bottom: 0px;
  padding: 5px;
}

.beneficiary_chart_title {
  text-align: right;
  font-style: normal;
  /*font-size: 15px;*/
  font-size: 1rem;
  /*line-height: 29px;*/
  line-height: 1.933rem;
  font-family: "BrandonGrotesque-Medium";
  margin-bottom: 0px;
  letter-spacing: 0px;
  color: #e1dfdc;
  text-transform: uppercase;
}

.beneficiary_chart_number_stats {
  text-align: right;
  font-family: Champion-HTF-Bantamweight;
  font-style: normal;
  /*font-size: 66px;*/
  font-size: 4.4rem;
  /*line-height: 66px;*/
  line-height: 4.4rem;
  letter-spacing: 0px;

  text-transform: uppercase;
  margin-bottom: 0px;
}

.beneficiary_chart_number_stats.color-blue,
.beneficiary_chart_description_text .color-blue {
  color: #35b4bd;
}

.beneficiary_chart_number_stats.color-yellow,
.beneficiary_chart_description_text .color-yellow {
  color: #ffba18;
}

.beneficiary_chart_description_text {
  text-align: right;
  font-style: normal;
  /*font-size: 17px;*/
  font-size: 1.133rem;
  /*line-height: 16px;*/
  line-height: 1.067rem;
  font-family: "BrandonGrotesque-Regular";
  margin-bottom: 0px;
  letter-spacing: 0px;
  color: #f5f5f5;
}

.left-description {
  height: 230px;
  display: flex;
  align-items: flex-end;
  justify-content: space-evenly;
  flex-direction: column;
}

.chart-border {
  margin-top: 20px;
  border-right: 3px dotted #848484;
  margin-bottom: 15px;
}

.right-description .beneficiary_chart_title {
  text-align: left;
  font-style: normal;
  /*font-size: 15px;*/
  font-size: 1rem;
  /*line-height: 29px;*/
  line-height: 1.933rem;
  font-family: "BrandonGrotesque-Medium";
  margin-bottom: 0px;
  letter-spacing: 0px;
  color: #e1dfdc;
  text-transform: uppercase;
}

.right-description .beneficiary_chart_number_stats {
  text-align: left;
  font-family: Champion-HTF-Bantamweight;
  font-style: normal;
  /*font-size: 66px;
line-height: 66px;*/
  font-size: 4.4rem;
  line-height: 4.4rem;
  letter-spacing: 0px;

  text-transform: uppercase;
  margin-bottom: 0px;
}

.right-description .beneficiary_chart_description_text {
  text-align: left;
  font-style: normal;
  /*font-size: 17px;*/
  font-size: 1.133rem;
  /*line-height: 16px;*/
  line-height: 1.067rem;
  font-family: "BrandonGrotesque-Regular";
  margin-bottom: 0px;
  letter-spacing: 0px;
  color: #f5f5f5;
}

.right-description {
  height: 230px;
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  flex-direction: column;
}

.member_company_title_name {
  text-align: left;
  font-style: normal;
  /*font-size: 26px;*/
  font-size: 1.733rem;
  /*line-height: 25px;*/
  line-height: 1.667rem;
  font-family: "BrandonGrotesque-Medium";
  margin-bottom: 10px;
  letter-spacing: 0px;
  color: #ffffff;
  padding-left: 15px;
}

.member_company_profile_open_image {
  /*background-image: url("../images/astellas_png.png");
  background-position: center;
    background-repeat: no-repeat;
    background-size: contain;*/
  height: 150px;
  background-color: #edecec;
}

.left-gradient {
  background: transparent linear-gradient(270deg, #1a1a1a00 0%, #343332ba 35%, #343332 100%) 0% 0% no-repeat padding-box;
  height: 150px;
}

.right-gradient {
  background: transparent linear-gradient(90deg, #1a1a1a00 0%, #343332ba 35%, #343332 100%) 0% 0% no-repeat padding-box;
  height: 150px;
}

.overview_dashboard_page .small_slider .slick-prev {
  left: -20px !important;
}

.overview_dashboard_page .small_slider .slick-next {
  right: -20px !important;
}

.slider_vertical .slick-prev {
  top: 5px;
  width: 100%;
  left: 0px !important;
}

.slider_vertical .slick-prev:before {
  margin: 0 auto !important;
  /*transform: rotate(90deg) !important;*/
  content: "" !important;
  background: url("../images/slider_arrow_top.svg");
  height: 11px;
  width: 94px;
  display: block;
}

.slider_vertical {
  padding-top: 30px;
  padding-bottom: 30px;
}

.slider_vertical .slick-next {
  bottom: -25px !important;
  width: 100%;
  right: 0px !important;
  position: relative;
}

.slider_vertical .slick-next:before {
  margin: 0 auto !important;
  /*transform: rotate(90deg) !important;*/
  content: "" !important;
  background: url("../images/slider_arrow_bottom.svg");
  height: 11px;
  width: 94px;
  display: block;
}

.slider_vertical .slick-prev,
.slider_vertical .slick-next {
  height: 12px;
  /* width: 94px;*/
}

.slider_vertical .member_companies_card {
  height: 80px;
}

.slider_vertical .blue_border_holder {
  background-image: url("../images/blue_border_image_small.svg");
  width: 190px;
  height: 3px;
  display: block;
  margin-left: 5px;
  margin-bottom: 5px;
}

.slider_vertical_holder {
  /*height: 95%;*/
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.country_scroll_container {
  max-height: 100px;
  overflow-y: auto;
  min-height: 50px;
}

.country_scroll_container_big {
  max-height: 150px;
  overflow-y: auto;
  min-height: 50px;
}

.ncd_title {
  text-align: center;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 3.7px;
  color: #f0f0f0;
  text-transform: uppercase;
  font-family: Champion-HTF-Bantamweight;
  /* font-size: 100px;*/
  font-size: 4.667rem;
  line-height: 10rem;
  margin-bottom: 0px;
  padding-top: 8px;
}

.add_ncd_profile_open_image {
  background-image: none;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 150px;
}

.add_ncd_profile_open_image.diabetes_card {
  background-color: #3e2b00;
}

.add_ncd_profile_open_image .left-gradient {
  background: linear-gradient(270deg,
      #1a1a1a00 0%,
      #343332ba 35%,
      #343332 96%,
      #343332 100%),
    url(../images/diab_light.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.add_ncd_profile_open_image .right-gradient {
  background: linear-gradient(90deg, #1a1a1a00 0%, #343332ba 35%, #343332 100%),
    url(../images/diab_light.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.add_ncd_profile_open_image.cancer_card {
  background-color: #250319;
}

.add_ncd_profile_open_image.cancer_card .left-gradient {
  background: linear-gradient(270deg,
      #1a1a1a00 0%,
      #343332ba 35%,
      #343332 96%,
      #343332 100%),
    url(../images/cancer_purple_dark.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.add_ncd_profile_open_image.cancer_card .right-gradient {
  background: linear-gradient(90deg, #1a1a1a00 0%, #343332ba 35%, #343332 100%),
    url(../images/cancer_purple_dark.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.add_ncd_profile_open_image.cvd_card {
  background-color: #2b0303;
}

.add_ncd_profile_open_image.cvd_card .left-gradient {
  background: linear-gradient(270deg,
      #1a1a1a00 0%,
      #343332ba 35%,
      #343332 96%,
      #343332 100%),
    url(../images/cvd_dark.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.add_ncd_profile_open_image.cvd_card .right-gradient {
  background: linear-gradient(90deg, #1a1a1a00 0%, #343332ba 35%, #343332 100%),
    url(../images/cvd_dark.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.add_ncd_profile_open_image.resp_card {
  background-color: #01212e;
}

.add_ncd_profile_open_image.resp_card .left-gradient {
  background: linear-gradient(270deg,
      #1a1a1a00 0%,
      #343332ba 35%,
      #343332 96%,
      #343332 100%),
    url(../images/resp_dark.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.add_ncd_profile_open_image.resp_card .right-gradient {
  background: linear-gradient(90deg, #1a1a1a00 0%, #343332ba 35%, #343332 100%),
    url(../images/resp_dark.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.add_ncd_profile_open_image.other_card {
  background-color: #0d1c09;
}

.add_ncd_profile_open_image.other_card .left-gradient {
  background: linear-gradient(270deg,
      #1a1a1a00 0%,
      #343332ba 35%,
      #343332 96%,
      #343332 100%),
    url(../images/other_dark.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-position: 50% 170%;
}

.add_ncd_profile_open_image.other_card .right-gradient {
  background: linear-gradient(90deg, #1a1a1a00 0%, #343332ba 35%, #343332 100%),
    url(../images/other_dark.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-position: 50% 170%;
}

.slider_vertical_holder .add_ncd_card.cancer_card .add_ncd_card_descriptionholder p,
.slider_vertical_holder .add_ncd_card.cvd_card .add_ncd_card_descriptionholder p,
.slider_vertical_holder .add_ncd_card.resp_card .add_ncd_card_descriptionholder p,
.slider_vertical_holder .add_ncd_card.other_card .add_ncd_card_descriptionholder p,
.slider_vertical_holder .add_ncd_card.diabetes_card .add_ncd_card_descriptionholder p {
  text-align: center;
  letter-spacing: 0px;
  color: #ffffff;
  font-style: normal;
  /*font-size: 22px;*/
  font-size: 1.467rem;
  /*line-height: 41px;*/
  line-height: 2.733rem;
  font-family: "BrandonGrotesque-Regular";
  margin-bottom: 0px;
  font-weight: 100;
  padding: 7px 5px;
}

.slider_vertical_holder .add_ncd_card_descriptionholder:before {
  background: none;
}

.white_border_holder_small {
  background-image: url("../images/white_border_image_small.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 2px;
  width: 190px;
  height: 3px;
  display: block;
  margin-left: 5px;
  margin-bottom: 5px;
}

.slider_vertical_holder .add_ncd_card,
.slider_vertical_holder .add_ncd_card.cancer_card .add_ncd_card_descriptionholder,
.slider_vertical_holder .add_ncd_card.cvd_card .add_ncd_card_descriptionholder,
.slider_vertical_holder .add_ncd_card.resp_card .add_ncd_card_descriptionholder,
.slider_vertical_holder .add_ncd_card.other_card .add_ncd_card_descriptionholder,
.slider_vertical_holder .add_ncd_card.diabetes_card .add_ncd_card_descriptionholder {
  height: 100px;
}

.slider_vertical_holder .add_ncd_card.other_card .add_ncd_card_descriptionholder,
.slider_vertical_holder .add_ncd_card.other_card:hover .add_ncd_card_descriptionholder {
  background-position: 25% 90%;
}

.slider_vertical_holder .add_ncd_card:hover {
  transform: none;
}

.ncdlist_vertical_holder {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
}

.white_border_holder {
  background-image: url("../images/white_border_image.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 3px;
  margin-top: 2px;
}

.member_profile_card_open_cat_sub_title {
  text-align: left;
  letter-spacing: 0px;
  color: #ffffff;
  font-style: normal;
  font-weight: 300;
  /*font-size: 11px;*/
  font-size: 0.733rem;
  /*line-height: 14px;*/
  line-height: 0.933rem;
  font-family: "BrandonGrotesque-Regular";
  margin-bottom: 0px;
  margin-top: 5px;
}

.lmi_country_profile_open_image {
  height: 150px;
  background-color: #edecec;
}

.h-150px {
  height: 150px;
}

.slider_vertical .lmi_countries_card {
  height: 90px;
  margin-bottom: 10px;
}

.slider_vertical .lmi_countries_card .lmi_contries_card_titleholder p {
  padding-bottom: 5px;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.filter_header_spacing {
  padding: 0px 10% 0px 10% !important;
}

.member_profile_card_leftsection {
  max-height: 360px;
  overflow-y: auto;
}

.maplegend .list-group.list-group-horizontal .list-group-item {
  padding: 8px;
  color: #1a1a1a;
  font-family: "BrandonGrotesque-Regular";
  font-size: 15px;
}

.prog_list_holder_main.member_company_list .border-right-grey p img {
  max-height: 21px;
}

.prog_list_holder_main.member_company_list .border-right-grey p {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.prog_list_holder_main.member_company_list .border-right-grey {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Overview Dashboard Page style ends */

/* Leaflet map css */
.map-holder .leaflet-bottom .leaflet-bar {
  border: 0 !important;
}

.map-holder .leaflet-control-attribution.leaflet-control {
  display: none;
}

.map_legendbg {
  background: transparent linear-gradient(90deg, #a2c9cb 0%, #7ccbd0 37%, #2ca6b8 67%, #055e85 100%) 0% 0% no-repeat padding-box;
  height: 22px;
}

.map_legend_gradholder {
  width: 250px;
}

.map_legend_listgroup {
  display: none !important;
}

.map_legend_gradholder div {
  font-size: 15px;
  color: #1a1a1a;
  font-family: "BrandonGrotesque-Regular";
}

.benpop_maplegend .list-group-item {
  border: none;
  padding-bottom: 0px;
  font-size: 15px;
  color: #1a1a1a;
  font-family: "BrandonGrotesque-Regular";
  text-align: left;
}

.benpop_maplegend .list-group-item i {
  /* border:1px solid #ddd; */
}

.leaflet-tooltip {
  background-color: #343332 !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  border-radius: 4px !important;
  opacity: 1 !important;
  border: none !important;
  color: #fff !important;
  padding: 10px 10px !important;
  max-width: 290px;
  max-height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
}

.leaflet-tooltip-left:before {
  right: 0;
  margin-right: -12px;
  border-left-color: #343332 !important;
}

.leaflet-tooltip-left:after {
  border-right-color: #343332 !important;
}

.leaflet-tooltip p {
  margin-bottom: 5px !important;
}

.leaflet-tooltip p {
  font-family: BrandonGrotesque-Regular;
  font-size: 15px;
}

.popup_subtitle {
  border: 1px solid red;
}

.drilldownMap {
  margin-top: 21px;
  display: inline-block;
  background: #52B335;
  border-radius: 50%;
  padding: 2px 10px;
  position: absolute;
  right: 6%;
  z-index: 999;
  cursor: pointer;
  font-size: 18px;
}

.drilldownMap>i {
  color: #fff;
  font-weight: 500;
}

.overMapIndiacator {
  margin-top: 21px;
  display: flex;
  align-items: center;
  flex-direction: row;
  position: absolute;
  right: 4%;
  z-index: 999;
}

.overMapIndiacator .partnersData { 
  padding: 5px 8px;
  margin-right: 6px;
  font-size: 13px;
  color: #fff;
  border-radius: 3px;
  background: #930c62;
  cursor: pointer;
}

.overMapIndiacator.remove {
  right: calc(4% + 40px);
}

.refreshMap {
  /* margin-top: 21px; */
  display: inline-block;
  background: #930c62;
  border-radius: 50%;
  padding: 4px 8px;
  /* position: absolute;
  right: 4%;
  z-index: 999; */
  cursor: pointer;
}

.refreshMap.partners {
  margin-top: 21px;
  display: inline-block;
  background: #930c62;
  border-radius: 50%;
  padding: 4px 8px;
  position: absolute;
  right: 4%;
  z-index: 999;
  cursor: pointer;
}

.refreshMap>i {
  color: #fff;
  font-weight: 500;
}

.leaflet-popup-content-wrapper {
  background-color: #343332 !important;
  color: #fff !important;
}

.popup_title {
  margin: 0px !important;
}

.leaflet-popup-content {
  margin: 5px !important;
}

.secondary-ncd-overview-dashboard {
  background-color: #3e3c3b !important;
}

.leaflet-popup-tip {
  background: #343332 !important;
}

.chartHeaderText {
  background-color: #343332;
  padding-top: 15px;
}

.dropdown-for-desktop {
  display: block;
}

.dropdown-for-mobile {
  display: none;
}

#StayTop {
  /* position: fixed;
  top: 0; */
  width: 100%;
  /* display: block; */
  transition: top 0.3s;
  /* background-color: #fff; */
  z-index: 9;
  /* border-bottom: 1px solid #eaeaea; */
}

.CookieConsent {
  z-index: 9999 !important;
}

.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
  height: 450px;
}

.geographic-details h4 {
  color: #E16D41;
  text-transform: uppercase;
  font-size: 16px;
  font-family: "BrandonGrotesque-Medium";
  margin: 12px 0 0;
}

.geographic-details > span {
  color: #000;
  font-size: 15px;
  font-family: "BrandonGrotesque-Regular";
}

.geographic-details p > span,
p.collective-map-description {
  color: #000;
  font-size: 15px;
  font-family: "BrandonGrotesque-Regular";
  cursor: pointer;
}

.geographic-details p > span:hover,
.geographic-details p > span.active {
  text-decoration: underline;
}

.geographic-details p > span.active { font-weight: 600; }

.frameWork-1.active { border: 3px solid #f65d34; }
.frameWork-2.active { border: 3px solid #81b449; }
.frameWork-3.active { border: 3px solid #1da0bc; }

/* partners map CSS */
.actorFilterList .activeAll {
  background: #fff !important;
  color: #3E3E3E !important;
}
.actorFilterList .activeplwncds {
  background: #fff !important;
  color: #52b335 !important;
}
.actorFilterList .government {
  background: #fff !important;
  color: #015c84 !important;
}
.actorFilterList .activeHrh {
  background: #fff !important;
  color: #0b7451 !important;
}
.actorFilterList .activeInterbodies {
  background: #fff !important;
  color: #930c62 !important;
}
.actorFilterList .activePrivatesector {
  background: #fff !important;
  color: #c41111 !important;
}
.actorFilterList .activeCivilsociety {
  background: #fff !important;
  color: #ffba18 !important;
}

/* #network-chart {
  border: 1px solid #333534;
} */

#network-chart {
  width: 300px;
  position: absolute;
  right: 0px;
  bottom: 0px;
  z-index: 1000;
}

#network-chart .heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #333534;
}

#network-chart .heading h4 {
  padding: 4px 9px;
  margin: 0;
  text-transform: uppercase;
  font-size: 14px;
  color: #fff;
}

#network-chart .heading button {
  border: 0;
  padding: 0px 7px;
  margin-right: 5px;
  font-size: 17px;
  line-height: 25px;
  font-weight: 700;
  background: #333534;
  color: #fff;
}

.network-diagram.maximum-height {
  height: auto;
}

.network-diagram.minimum-height { 
  height: 0 !important; 
  overflow: hidden !important;
}

.maplegend .actorFilterList .disable { 
  opacity: 0.3;
  pointer-events: none;
}

.partnersLogoSection .partnersLogoContainer {
  align-items: center;
  justify-content: center;
}

.ncd-dashboard-section {
  display: flex;
  align-items: center;
}

footer p.text-left.db-description.mt-0.mb-1 > p {  margin: 0; }

.drawer.is-form .wpcf7-response-output{
  color: #ff3f3f;
}

.sidepopup-content-wrap .sidebarLabelCheck {
  font-size: 18px;
  line-height: 28px;
}

.form-footer-info {
  margin-top: 23px;
  font-size: 13px;
  line-height: 26px;
}

.quest-ans-div ul li{
  color: #212529;
  font-family: "Sentinel-Book";
  font-size: 1.1rem !important;
}

.quest-ans-div ul li .ans-text-list .ans-text, .faqs-quest-section-content .quest-ans-div .ans-text a{
  color: #f26432;
  font-weight: bold;
}

.section1Dash .container .exploreallbtnholder .row .col-md-4{

  display: flex;
  align-items: center;

}

