@media screen and (min-width: 1200px) and (max-width: 1300px) {
    .filter_header_spacing {
        padding: 0px 5% 0px 5% !important;
    }

    .overview_dashboard_page .slick-prev {
        left: -50px !important;
    }

    .overview_dashboard_page .slick-next {
        right: -50px !important;
    }

    .slider_vertical .slick-prev {
        left: 0px !important;
    }

    .slider_vertical .slick-next {
        right: 0px !important;
    }
}

@media screen and (max-width: 1200px) {

    .appnavbar>nav li {
        margin-left: 0.5rem;
        position   : relative;
        padding    : 1.4rem 0.3rem 1.4rem 0;
    }

    .appnavbar>nav {
        padding: 0rem;
    }

    .appnavbar>nav li a { font-size: 11px; }

    .overview_dashboard_page .filter-title {
        /*font-size: 12px;*/
        font-size: 0.8rem;
        height   : 46px;
    }

    .overview_dashboard_page .filter-image-holder {
        margin: 0 auto;
    }

    .overview_dashboard_page .filter-image-holder {
        height: 50px;
        width : 50px;
    }

    .overview_dashboard_page .filter-image-holder img {
        height: 25px;
        width : 25px;
    }

    .overview_dashboard_page .filter-img-description {
        /*font-size: 50px;
    line-height: 50px;*/
        font-size  : 3.333rem;
        line-height: 3.333rem;
        margin-top : 0px;
    }

    .overview_dashboard_page .slick-next {
        right: -28px !important;
    }

    .overview_dashboard_page .slick-prev {
        left: -28px !important;
    }

    .slider_vertical .slick-prev {
        left: 0px !important;
    }

    .slider_vertical .slick-next {
        right: 0px !important;
    }

    .slider_vertical .member_companies_card {
        width: 100%;
    }

    .slider_vertical .blue_border_holder {
        width      : 90%;
        margin-left: 5%;
    }

    .white_border_holder_small {
        width      : 90%;
        margin-left: 5%;
    }

    .ncd-dashboard-section {
        flex-direction: column;
        align-items: start;
    }

    .appbarDropDown .dropdown-toggle::before { top: -6px }


}

@media screen and (max-width: 991px) {
    .dbtempcardholder {
        margin-bottom: 2rem;
    }

    .mastimgholder {
        display    : flex;
        align-items: center;
    }

    .peopleimgholder {
        text-align   : center;
        margin-bottom: 2rem;
    }

    .peopleimgholder img {
        max-width: 50%;
    }

    .appnavbar>nav li {
        margin-left  : 0rem;
        position     : relative;
        padding      : 0.3rem;
        /* border-bottom: 1px solid #ddd; */
    }

    .signupbtn {
        width        : fit-content;
        margin-top   : 10px;
        margin-bottom: 10px;
    }

    .mast-imagecontainer>div {
        left: 0rem;
    }

    .mt-5.mb-5.exploreallbtnholder,
    .mb-5.mt-4.launchdbbtnholder {
        margin-bottom: 1rem !important;
        margin-top   : 1rem !important;
    }

    .explorealltempbtn {
        margin-top: 0px !important;
    }

    .visible-phone {
        display: block;
    }

    .secondary-navholder {
        display: none;
    }

    .appnavbar>nav.container {
        max-width: 100%;
    }

    .secondary-navholder ul a { padding: 0.8rem 1rem; }

    .secondary-ncd-overview-dashboard .container {
        max-width: 100%;
    }

    .secondary-ncd-overview-dashboard {
        display: block;
    }

    .secondary-ncd-overview-dashboard .faqs-navLink.nav-link {
        right: 0px;
    }

    .overview_dashboard_page .filter_header_spacing.container-fluid {
        max-width    : 100%;
        padding-right: 15px !important;
        padding-left : 15px !important;
    }

    .mastheader-holder {
        padding-top: 4rem;
    }

    .resourcelogodivcontainer {
        margin-top      : 34px;
        height          : 75%;
        width           : 100%;
        background-color: #f1ebed;
        display         : flex;
        justify-content : center;
    }

    .overview_dashboard_page .filter-title {
        /*font-size: 12px;*/
        font-size: 0.8rem;
        height   : 46px;
    }

    .overview_dashboard_page .dropdown-for-mobile .filter-title { height: auto; }

    .overview_dashboard_page .filter-image-holder {
        margin: 0 auto;
    }

    .overview_dashboard_page .filter-image-holder {
        height: 45px;
        width : 45px;
    }

    .overview_dashboard_page .filter-image-holder img {
        height: 25px;
        width : 25px;
    }

    .overview_dashboard_page .filter-img-description {
        /*font-size: 40px;
    line-height: 40px;*/
        font-size  : 2.667rem;
        line-height: 2.667rem;
        margin-top : 10px;
    }

    .overview_dashboard_page .slick-next {
        right: -28px !important;
    }

    .overview_dashboard_page .slick-prev {
        left: -28px !important;
    }

    .chart-border {
        border-right: none;
    }

    .slider_vertical .slick-prev {
        left: 0px !important;
    }

    .slider_vertical .slick-next {
        right: 0px !important;
    }

    .slider_vertical .lmi_countries_card,
    .slider_vertical_holder .add_ncd_card,
    .slider_vertical .member_companies_card {
        width: 100%;
    }

    .white_border_holder_small {
        width      : 90%;
        margin-left: 5%;
    }

    .slider_vertical .blue_border_holder {
        width      : 90%;
        margin-left: 5%;
    }

    .slider_vertical_holder .add_ncd_card .add_ncd_card_descriptionholder p {
        /*font-size: 15px !important;*/
        font-size    : 1rem !important;
        /*line-height: 32px !important;*/
        line-height  : 2.133rem !important;
    }

    .slider_vertical .lmi_contries_card_titleholder p {
        /*font-size: 15px !important;*/
        font-size    : 1rem !important;
        /*line-height: 21px !important;*/
        line-height  : 1.4rem !important;
    }

    .slider_vertical .lmi_countries_card .lmi_contries_card_titleholder p {
        height: auto;
    }

    .slider_vertical_holder .add_ncd_card_descriptionholder {
        height: 100% !important;
    }

}

@media screen and (max-width: 1000px) {
    .container-fluid .refreshMap {
        right: 6%;
    }
    .overview_dashboard_page .overview-map-container {
        padding-left: 30px;
        padding-right: 30px;
        background-color: #e3deda;
    }
    .explorealltempbtn {
        padding  : 10px;
        font-size: 12px;
    }

    button.explorealltempbtn {
        font-size: 12px;
        padding  : 10px;
    }

    .navbar-brand {
        padding-left: 10px;
    }

    .navbar-toggler {
        margin-right: 10px;
    }

    .peopleimgholder img {
        max-width: 100%;
    }

    .mastheader-holder,
    .dashboardtemplate-holder {

        padding: 2rem 1rem 2rem 1rem;

    }

    .mastheader-holder {
        padding-top: 4rem;
    }

    .appnavbar>nav li {

        padding-left: 10px;
    }

    .customtabsholder .nav-tabs .nav-link {
        margin-right: 0px;
    }

    .customtabsholder .nav-tabs .nav-link.active,
    .customtabsholder .nav-tabs .nav-link {
        font-size: 1rem;
    }

    .covidportallogomainholder {
        display        : flex;
        align-items    : center;
        justify-content: center;
        flex-direction : column;
    }

    .resourcelogodivcontainer {
        margin-top      : 0px;
        height          : auto;
        width           : auto;
        background-color: #f1ebed;
        display         : flex;
        justify-content : center;
        padding         : 20px;
    }

    .resourceinfo {
        margin-top: 2rem;
    }

    .countryprofiledivcontainer {
        width        : 100%;
        box-shadow   : 0px 0px 6px #00000040;
        border-radius: 14px;
        padding      : 15px;
    }

    .countryname {
        font-size: 1.2rem;
    }

    .order-xs-1 {
        -ms-flex-order: 1;
        order         : 1;
    }

    .order-xs-2 {
        -ms-flex-order: 2;
        order         : 2;
    }

    .mb-5.resourceholder {
        margin-bottom : 0px !important;
        padding-bottom: 0px !important;
    }

    .pt-xs-0 {
        padding-top: 0rem !important;
    }

    .faqsPage .faqs-title {
        margin-top: 3rem !important;
    }

    span.ans-text-list {
        position: static;
    }

    .landingPageFooter .sectiontitle {
        margin-top: 2rem;
    }

    .sidebar-overlay .sidemodalpopup {
        width: 100% !important;
    }

    .appbarDropDown .dropdown-menu {
        width     : 100%;
        border-top: 0;
        box-shadow: none;
        border    : 0;
    }

    .overview_dashboard_page .nav-tabs .nav-item {
        width     : 20%;
        padding   : 20px 6px 20px 6px;
        border    : none;
        transition: 0.3s linear;
    }

    .overview_dashboard_page .filter_img_text_holder {
        padding: 5px 8px;
    }

    .overview_dashboard_page .filter-img-description {
        font-size : 1.667rem;
        margin-top: 0px;
    }

    .dropdown-for-desktop {
        display: none;
    }

    .dropdown-for-mobile {
        display: block;
    }

    .overview_dashboard_page .nav-tabs .dropdown-list {
        z-index: auto !important;
    }

    .dropdown-for-mobile .dropdown-menu {
        transform   : none !important;
        position    : fixed !important;
        top         : 84px !important;
        left        : 0px !important;
        /* width    : 345px; */
        width       : 100%;
        z-index     : 9999;
    }

    .prog_list_holder_main {
        height    : 200px;
        overflow-y: auto;
        /* width     : 318px; */
        width: 100%;
        padding-bottom: 50px;
    }

    .filter-dropdown-control .show.dropdown-menu {
        width: 100%;
        bottom: 0;
    }

    .filter-dropdown-control .dropdown-menu form .searchholder input {
        /* width: 318px; */
        width: 100%;
    }

    .member_programs_dropdown_uparrow_holder,
    .bene_population_dropdown_uparrow_holder,
    .member_companies_dropdown_uparrow_holder,
    .add_ncds_dropdown_uparrow_holder,
    .lmi_countries_dropdown_uparrow_holder {
        position : absolute;
        top      : 140px;
        right    : -12px;
        width    : 100%;
        transform: rotate(-90deg);
    }

    .overview_dashboard_page {
        overflow-x: hidden;
    }

    .dropdown-for-mobile.dropdown-topHeader {
        /* width: 310px; */
        width: 100%;
    }

    .filter-dropdown-control .dropdown-menu form .searchholder {
        position: relative;
    }

    .filter-dropdown-control .dropdown-menu form .searchholder i {
        top: 8px;
    }

    .dropdown-for-mobile.dropdown-topHeader .beneficiary-img-div {
        background-color: #930c62;
    }

    .dropdown-for-mobile.dropdown-topHeader .member-img-div {
        background-color: #c41111;
    }

    .dropdown-for-mobile.dropdown-topHeader .member-comp-img-div {
        background-color: #0b7451;
    }

    .dropdown-for-mobile.dropdown-topHeader .address-ncd-img-div {
        background-color: #ffba18;
    }

    .dropdown-for-mobile.dropdown-topHeader .lmi-count-img-div {
        background-color: #f26432;
    }

    .dropdown-for-mobile.dropdown-topHeader .filter-img-description {
        font-size: 2.667rem;
        color    : #fff;
        margin-bottom: 0 !important;
    }

    .overview_dashboard_page .member_companies_card {
        width: 170px;
    }

    .member_companies_card_description:before {
        width: 155px;
    }

    .add_ncd_card {
        height: 200px;
        width : 183px;
    }

    .add_ncd_card_descriptionholder:before {
        width: 170px;
    }

    .add_ncd_card_titleholder p {
        font-size: 1rem;
    }

    .add_ncd_card_descriptionholder .cat_title {
        font-size: 1rem;
    }

    .lmi_countries_card {
        width: 183px;
    }

    .lmi_countries_card_descriptionholder:before {
        width: 170px;
    }

    .feedbackIconDiv {
        display: none;
    }

    .overview_dashboard_page .analytical-db-link {
        display: none;
    }

    .dropdown-for-mobile.onScrollTop .dropdown-menu {
        top       : 44px !important;
        max-height: 100vh !important;
    }
    .CookieConsent{
        z-index: 9999 !important;
    }
    .CookieConsent div .cookies-strip-text{
        left: 12px;
        font-size: 1rem;
    }
    #rcc-decline-button{
        right: -18px;
        font-size: 10px;
    }
    #rcc-confirm-button{
        right: 0;
        top: 0px;
        font-size: 10px;
    }
    .appnavbar .stoptblogoimg{
        width: 72px !important;
    }
    .dropdown-for-mobile .dropdown-menu{
        background-color: transparent !important;
        border: 0 !important;
        padding-top: 0 !important;
    }
    .mobile-bg-color-col{
        background-color: #1a1a1a;
        padding-top: 10px;
    }
    .dropdown-for-mobile.dropdown-topHeader .row{
        
    padding: 0 10px 6px;
    display: flex;
    justify-content: center;
    align-items: center;

    }

}

@media screen and (min-width: 1000px) {

    .visible-phone {
        display: none;
    }

    .overview_dashboard_page
  .nav-tabs
  li.nav-item:hover
  .filter-dropdown-control.dropdown
  .dropdown-menu {
  display: block ;
  height: auto ;
  max-height: 313px ;
  visibility: visible ;
  top: 0px;
}


}

@media screen and (max-width: 767px) {
    #network-chart {
        position: static;
        margin: 0 auto;
    }

    .actorFilterList {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
     
    .actorFilterList li { margin: 0 !important; }
    .legendMapList ul {
        display: flex;
        flex-wrap: wrap;
        padding-left: 0 !important;
        margin: 0 !important;
    }
    .legendMapList ul li { 
        margin: 0 !important;
        margin-right: 5px !important;
    }

    .leaflet-container .leaflet-top { top: unset; }

    .text-right.collectiveTitle { text-align: left !important; }
}

@media only screen and (min-device-width: 1366px) and (max-device-width: 1530px) {

    .partnerActive a,
    .collectiveActive a{
       font-size: 1.25rem;
    }
}    
